import { Yup } from 'cng-web-lib'
import * as ValidationRegex from './ValidationRegex'
import ValidationMessageTranslationText from './ValidationMessageTranslationText'
import ShippingInstructionTranslationText from 'src/views/shippinginstruction/ShippingInstructionTranslationText'

function BookingTypeValidationSchema() {
  const validationMessageTranslation = ValidationMessageTranslationText()
  const siTranslatedTextsObject = ShippingInstructionTranslationText()

  Yup.addMethod(Yup.string, 'loadExisting', function (message) {
    return this.test('loadExisting', message, function (val) {
      return false
    })
  })

  return Yup.object({
    shipmentType: Yup.string()
      .nullable()
      .when('directSi', {
        is: true,
        then: Yup.string().required(
          validationMessageTranslation.requiredMessage
        )
      }),

    bookingRefNo: Yup.string()
      .nullable()
      .when('directSi', {
        is: true,
        then: Yup.string().required(
          validationMessageTranslation.requiredMessage
        )
      })
      .when('loadExistingBooking', {
        is: true,
        then: Yup.string()
          .required(validationMessageTranslation.requiredMessage)
          .loadExisting(siTranslatedTextsObject.bookingExist)
      })
  })
}

export default BookingTypeValidationSchema
