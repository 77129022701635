import { Yup } from 'cng-web-lib'
import * as ValidationRegex from 'src/views/shippinginstruction/shared/validation/ValidationRegex'
import ValidationMessageTranslationText from 'src/views/shippinginstruction/shared/validation/ValidationMessageTranslationText'

function DocumentValidationSchema() {

  const validationMessageTranslation = ValidationMessageTranslationText()

  return Yup.object({

    // Uploaded docs
    shippingInfoDocuments: Yup.array(
      Yup.object({
        docType: Yup.string().required(validationMessageTranslation.requiredMessage).max(10, validationMessageTranslation.wordLimitExceedMsg).nullable(),
        description: Yup.string().required(validationMessageTranslation.requiredMessage).max(1024, validationMessageTranslation.wordLimitExceedMsg).nullable()
      })
    ).nullable(),

    // Pending docs
    pendingshippingInfoDocuments: Yup.array(
      Yup.object({
        docType: Yup.string().required(validationMessageTranslation.requiredMessage).max(10, validationMessageTranslation.wordLimitExceedMsg).nullable(),
        description: Yup.string().required(validationMessageTranslation.requiredMessage).max(1024, validationMessageTranslation.wordLimitExceedMsg).nullable()
      })
    ).nullable(),

    })
}

export default DocumentValidationSchema


    
