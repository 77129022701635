import { BasePage as CngBasePage } from 'cng-web-lib'
import ViewOnlySIPage from './ViewOnlySIPage.js'
import React from 'react'
import useBreadcrumbNameMap from 'src/navigations/breadcrumbs/Breadcrumb_ShippingInstruction'
import ShippingInstructionTranslationText from '../ShippingInstructionTranslationText'
import CreateSIViewSIDraft from 'src/views/shippinginstruction/createsi/viewsidraft/CreateSIViewSIDraftPage'

function WrappedViewOnlySIPage(props) {
  const breadcrumbNameMap = useBreadcrumbNameMap()
  const translatedTextsObject = ShippingInstructionTranslationText()
  return (
    <CngBasePage
      moduleTitle={translatedTextsObject.title}
      renderPage={(showSnackbar) => (
        <ViewOnlySIPage showNotification={showSnackbar} {...props} />
      )}
      extraCngBreadcrumbNameMap={breadcrumbNameMap}
    />
  )
}

function WrappedViewOnlySIViewSIPage(props) {
  const breadcrumbNameMap = useBreadcrumbNameMap()
  const translatedTextsObject = ShippingInstructionTranslationText()
  return (
    <CngBasePage
      moduleTitle={translatedTextsObject.title}
      renderPage={(showSnackbar) => (
        <CreateSIViewSIDraft
          showNotification={showSnackbar}
          isViewSi={true}
          {...props}
        />
      )}
      extraCngBreadcrumbNameMap={breadcrumbNameMap}
    />
  )
}

export {
  WrappedViewOnlySIPage as ViewOnlySIPage,
  WrappedViewOnlySIViewSIPage as ViewOnlySIViewSIPage
}
