import React, { useEffect, useRef, useState } from 'react'
import { constants, useServices } from 'cng-web-lib'

import Api from './api'

const VALUE_ALL = 'ALL'
export default class ShippingInstructionApiParser {
  static parseSortingType(data) {
    let tempList = []
    data.map((v) => {
      let temp = {
        text: v.sortDesc,
        value: v.sortCode
      }
      tempList.push(temp)
    })
    return tempList
  }

  /**
   * parses the all the data into an array for all the statuscodes so that all bookings will be viewed on one page
   * @param {*} data
   * @returns {... data, fullList}
   */
  static parseHelpdeskStatusCodeAndCount(data) {
    let concatList = data.pendingStatusList.concat(data.processedStatusList)
    let fullList = concatList.map((v) => ({
      ...v,
      checked: true,
      eligible: true
    }))
    if (fullList[0] !== undefined) {
      let newRecord = { ...fullList[0] }
      newRecord.statusDesc = VALUE_ALL
      fullList = [...fullList, newRecord]
      console.log(fullList)
    }
    return { ...data, fullList }
  }

  static parseStatusCodeAndCount(data, showMyTask, isHelpdesk) {
    if (showMyTask === undefined) {
      showMyTask = false
    }
    console.log(data)
    console.log('parseStatusCodeAndCount:: showMyTaskcc' + showMyTask)

    data.totalCount =
      data.approvedCount +
      data.blIssuedCount +
      data.blSurrenderedCount +
      data.cancelledCount +
      data.confirmedCount +
      data.pendingApprovalCount +
      data.pendingCarrierConfirmCount +
      data.pendingResubmitCount +
      data.pendingShipperConfirmCount +
      data.bookingCancelledCount
    

    let siStatusList = []

    let approvedStatus = this.parseStatus(
      data.approvedList,
      data.approvedCount,
      showMyTask
    )
    siStatusList.push(approvedStatus)

    siStatusList.push(
      this.parseStatus(data.blIssuedList, data.blIssuedCount, showMyTask)
    )
    siStatusList.push(
      this.parseStatus(
        data.blSurrenderedList,
        data.blSurrenderedCount,
        showMyTask
      )
    )
    siStatusList.push(
      this.parseStatus(data.cancelledList, data.cancelledCount, showMyTask)
    )
    siStatusList.push(
      this.parseStatus(data.confirmedList, data.confirmedCount, showMyTask)
    )
    siStatusList.push(
      this.parseStatus(
        data.pendingApprovalList,
        data.pendingApprovalCount,
        false
      )
    )
    siStatusList.push(
      this.parseStatus(
        data.pendingCarrierConfirmList,
        data.pendingCarrierConfirmCount,
        showMyTask
      )
    )
    siStatusList.push(
      this.parseStatus(
        data.pendingResubmitList,
        data.pendingResubmitCount,
        showMyTask
      )
    )
    siStatusList.push(
      this.parseStatus(
        data.pendingShipperConfirmList,
        data.pendingShipperConfirmCount,
        showMyTask
      )
    )

    siStatusList.push(
      this.parseStatus(
        data.bookingCancelledList,
        data.bookingCancelledCount,
        showMyTask)
    )

    if(isHelpdesk){
      data.totalCount = data.totalCount + data.pendingSubmissionCount;
      siStatusList.push(this.parseStatus(data.pendingSubmissionList, data.pendingSubmissionCount));
    } 

    let newRecord = { ...data.approvedList[0] }
    newRecord.statusDesc = VALUE_ALL

    siStatusList.push(newRecord)

    console.log("siStatusList", siStatusList)

    return { ...data, siStatusList }
  }

  static parseStatus(list, count, showMyTask) {
    let status = list != null ? list[0] : null
    status.statusDesc = status.statusDesc + ' (' + count + ')'

    status.checked = count > 0
    status.eligible = count > 0 && !showMyTask
    return status
  }

  static parseBlApplicationType(data) {
    data.map((elem) => {
      elem.statusDesc = elem.blApplnTypeDesc + '(' + elem.shippingCount + ')'
      elem.statusCode = elem.blApplnType
      elem.checked = true
      elem.eligible = true
      return elem
    })

    let newRecord = { ...data[0] }
    newRecord.totalCount = data.reduce((a, b) => a + b.shippingCount, 0)
    newRecord.statusDesc = VALUE_ALL
    //newRecord.statusCode = VALUE_ALL

    data = [...data, newRecord]

    return data
  }

  static parsePartyId(data) {
    return data.map((v) => ({ ...v, checked: true, eligible: true }))
  }
}
