import { Yup } from 'cng-web-lib'
import * as ValidationRegex from 'src/views/shippinginstruction/shared/validation/ValidationRegex'
import ValidationMessageTranslationText from 'src/views/shippinginstruction/shared/validation/ValidationMessageTranslationText'
import FRBValidationMessageTranslationText from 'src/views/freightbooking/shared/validation/ValidationMessageTranslationText'
import * as FrbValidationRegex from 'src/views/freightbooking/shared/validation/ValidationRegex'

function ContactValidationSchema() {
  const validationMessageTranslation = ValidationMessageTranslationText()
  const frbValidationMessageTranslation = FRBValidationMessageTranslationText()

  function validateShpName(value) {
    return ValidationRegex.partyNameRegx.test(value)
  }

  function validatePartyNameLength(value) {
    return ValidationRegex.partyNameLengthRegx.test(value)
  }

  function validatePartyNamePILLength(value) {
    return ValidationRegex.partyNamePILLengthRegx.test(value)
  }

  function validatePartyAddressLength(value) {
    return ValidationRegex.partyAddressLengthRegx.test(value)
  }

  function validatePartyAddressPILLength(value) {
    return ValidationRegex.partyAddressPILLengthRegx.test(value)
  }

  function validateEmoji(value) {
    return ValidationRegex.emojiRegx.test(value)
  }

  function validateEmojiEndNewLine(value) {
    return value ? ValidationRegex.emojiEndNewLineRegx.test(value) : true
  }

  function validateAddressMaxLine(value) {
    if (value == null) {
      return true
    }
    return ValidationRegex.addressMaxLinesRegx.test(value)
  }

  function validateEmailList(value) {
    if (value == null) {
      return true
    }
    return ValidationRegex.emailListRegx.test(value)
  }

  function validateEmailListLength(value) {
    if (value == null) {
      return true
    }
    return ValidationRegex.emailListLengthRegx.test(value)
  }

  function validateAgentPartyAddressMaxLine(value) {
    if (value == null) {
      return true
    }
    return ValidationRegex.agentAddressMaxLineRegx.test(value)
  }

  function validateAgentPartyAddressMaxLength(value) {
    if (value == null) {
      return true
    }
    return ValidationRegex.agentAddressLengthRegx.test(value)
  }

  function validateBillOfLadingNo(value) {
    if (value == null) {
      return true
    }
    return ValidationRegex.blNumberRegex.test(value)
  }

  function validateEmailList(value) {
    if (value == null || value.length < 1) {
      return true
    }

    return value.every((element) =>
      FrbValidationRegex.emailListRegx.test(element)
    )
  }

  const PIL_PARTY = 'Pacific International Lines (Pte ) Ltd'

  return Yup.object({
    // References
    billOfLadingAppType: Yup.string()
      .required(validationMessageTranslation.requiredMessage)
      .max(5, validationMessageTranslation.wordLimitExceedMsg)
      .nullable(),
    nvoccRefNo: Yup.string().nullable(),
    //original_no: Yup.string().test("original_no", validationMessageTranslation.invalidHSCode, value => validateOriginalNo(value)).nullable(),

    // Bill of Lading
    shipper_remarks: Yup.string()
      .max(1024, validationMessageTranslation.wordLimitExceedMsg)
      .nullable(),

    // Parties
    shipperName: Yup.string()
      .required(validationMessageTranslation.requiredMessage)
      .nullable()
      .max(100, validationMessageTranslation.wordLimitExceedMsg)
      .test(
        'shipper_name',
        validationMessageTranslation.invalidPartyName,
        (value) => validateShpName(value)
      )
      .test(
        'shipperName',
        validationMessageTranslation.invalidEmojiMsg,
        (value) => validateEmoji(value)
      )
      .when('isPilParty', {
        is: true,
        then: Yup.string()
          // .required(validationMessageTranslation.requiredMessage).nullable()
          .test(
            'shipperNamePilLength',
            validationMessageTranslation.invalidPartyNamePILLengthMsg,
            (value) => validatePartyNamePILLength(value)
          ),
        otherwise: Yup.string().test(
          'shipperNameLength',
          validationMessageTranslation.invalidPartyNameLengthMsg,
          (value) => validatePartyNameLength(value)
        )
      }),
    shipperAddress: Yup.string()
      .nullable()
      .max(512, validationMessageTranslation.wordLimitExceedMsg)
      .test(
        'shipper_address1',
        validationMessageTranslation.invalidEmojiEndNewLineMsg,
        (value) => validateEmojiEndNewLine(value)
      )
      .test(
        'shipper_address2',
        validationMessageTranslation.invalidAddressMsg,
        (value) => validateAddressMaxLine(value)
      )
      .test(
        'shipperAddressLength',
        validationMessageTranslation.invalidPartyAddressLengthMsg,
        (value) => validatePartyAddressLength(value)
      ),
    // .when('isPilParty', {
    //   is: true,
    //   then: Yup.string().test(
    //     'shipperAddressPilLength',
    //     validationMessageTranslation.invalidPartyAddressPILLengthMsg,
    //     (value) => validatePartyAddressPILLength(value)
    //   ),
    //   otherwise: Yup.string().test(
    //     'shipperAddressLength',
    //     validationMessageTranslation.invalidPartyAddressLengthMsg,
    //     (value) => validatePartyAddressLength(value)
    //   )
    // }),

    consigneeName: Yup.string()
      .required(validationMessageTranslation.requiredMessage)
      .nullable()
      .max(100, validationMessageTranslation.wordLimitExceedMsg)
      .test(
        'consignee_name1',
        validationMessageTranslation.invalidPartyName,
        (value) => validateShpName(value)
      )
      .test(
        'consignee_name2',
        validationMessageTranslation.invalidEmojiMsg,
        (value) => validateEmoji(value)
      )
      .when('isPilParty', {
        is: true,
        then: Yup.string().test(
          'consigneeNamePilLength',
          validationMessageTranslation.invalidPartyNamePILLengthMsg,
          (value) => validatePartyNamePILLength(value)
        ),
        otherwise: Yup.string().test(
          'consigneeNameLength',
          validationMessageTranslation.invalidPartyNameLengthMsg,
          (value) => validatePartyNameLength(value)
        )
      }),

    consigneeAddress: Yup.string()
      .nullable()
      .max(512, validationMessageTranslation.wordLimitExceedMsg)
      //.test("consignee_address", validationMessageTranslation.invalidAddressMsg, value => validateAddressMaxLine(value))
      .test(
        'consignee_address1',
        validationMessageTranslation.invalidEmojiEndNewLineMsg,
        (value) => validateEmojiEndNewLine(value)
      )
      .test(
        'consignee_address2',
        validationMessageTranslation.invalidAddressMsg,
        (value) => validateAddressMaxLine(value)
      )
      .test(
        'consigneeAddressLength',
        validationMessageTranslation.invalidPartyAddressLengthMsg,
        (value) => validatePartyAddressLength(value)
      ),
    // .when('isPilParty', {
    //   is: true,
    //   then: Yup.string()
    //     // .required(validationMessageTranslation.requiredMessage).nullable()
    //     .test(
    //       'consigneeAddressPilLength',
    //       validationMessageTranslation.invalidPartyAddressPILLengthMsg,
    //       (value) => validatePartyAddressPILLength(value)
    //     ),
    //   otherwise: Yup.string().test(
    //     'consigneeAddressLength',
    //     validationMessageTranslation.invalidPartyAddressLengthMsg,
    //     (value) => validatePartyAddressLength(value)
    //   )
    // }),

    usccNoNote: Yup.string().max(
      20,
      validationMessageTranslation.wordLimitExceedMsg
    ),

    destNotifyName: Yup.string()
      .required(validationMessageTranslation.requiredMessage)
      .nullable()
      .max(100, validationMessageTranslation.wordLimitExceedMsg)
      .test(
        'notify_partyName1',
        validationMessageTranslation.invalidPartyName,
        (value) => validateShpName(value)
      )
      .test(
        'notify_partyName2',
        validationMessageTranslation.invalidEmojiMsg,
        (value) => validateEmoji(value)
      )
      .when('isPilParty', {
        is: true,
        then: Yup.string().test(
          'notifyNamePilLength',
          validationMessageTranslation.invalidPartyNamePILLengthMsg,
          (value) => validatePartyNamePILLength(value)
        ),
        otherwise: Yup.string().test(
          'notifyNameLength',
          validationMessageTranslation.invalidPartyNameLengthMsg,
          (value) => validatePartyNameLength(value)
        )
      }),

    destNotifyAddress: Yup.string()
      .nullable()
      .max(512, validationMessageTranslation.wordLimitExceedMsg)
      .test(
        'notify_partyAddress1',
        validationMessageTranslation.invalidEmojiEndNewLineMsg,
        (value) => validateEmojiEndNewLine(value)
      )
      .test(
        'notify_partyAddress2',
        validationMessageTranslation.invalidAddressMsg,
        (value) => validateAddressMaxLine(value)
      )
      .test(
        'notifyAddressLength',
        validationMessageTranslation.invalidPartyAddressLengthMsg,
        (value) => validatePartyAddressLength(value)
      ),
    // .when('isPilParty', {
    //   is: true,
    //   then: Yup.string()
    //     // .required(validationMessageTranslation.requiredMessage).nullable()
    //     .test(
    //       'notifyAddressPilLength',
    //       validationMessageTranslation.invalidPartyAddressPILLengthMsg,
    //       (value) => validatePartyAddressPILLength(value)
    //     ),
    //   otherwise: Yup.string().test(
    //     'notifyAddressLength',
    //     validationMessageTranslation.invalidPartyAddressLengthMsg,
    //     (value) => validatePartyAddressLength(value)
    //   )
    // }),

    bookingRepresentativeDetail: Yup.string()
      .max(30, validationMessageTranslation.wordLimitExceedMsg)
      .nullable(),

    // partner_email_list: Yup.string()
    //   .test(
    //     'partner_email_list',
    //     validationMessageTranslation.invalidEmailListMsg,
    //     (value) => validateEmailList(value)
    //   )
    //   .test(
    //     'partner_email_list',
    //     validationMessageTranslation.exceedEmailListLengthMsg,
    //     (value) => validateEmailListLength(value)
    //   )
    //   .nullable(),

    // ---Disabled for shipper, only for carrier---

    agentAddress: Yup.string()
      .nullable()
      .test(
        'agent_partyAddress1',
        validationMessageTranslation.agentAddressExceedMaxLength,
        (value) => validateAgentPartyAddressMaxLength(value)
      )
      .test(
        'agent_partyAddress2',
        validationMessageTranslation.agentAddressExceedMaxLines,
        (value) => validateAgentPartyAddressMaxLine(value)
      ),

    billOfLadingNo: Yup.string().when('isCarrier', {
      is: true,
      then: Yup.string()
        .required(validationMessageTranslation.requiredMessage)
        .test(
          'bill_of_lading_no',
          validationMessageTranslation.invalidBLNumber,
          (value) => validateBillOfLadingNo(value)
        )
        .nullable(),
      otherwise: Yup.string().nullable()
    }),

    placeOfIssue: Yup.string().when('isCarrier', {
      is: true,
      then: Yup.string()
        .required(validationMessageTranslation.requiredMessage)
        .nullable(),
      otherwise: Yup.string().nullable()
    }),

    issueDate: Yup.date()
      .nullable()
      .transform((curr, orig) => orig === '' ? null : curr)
      .typeError(frbValidationMessageTranslation.FrbDateTypeErrorMsg)
      .when('isCarrier', {
        is: true,
        then: Yup.date()
          .required(validationMessageTranslation.requiredMessage)
          .nullable(),
        otherwise: Yup.date().nullable()
      }),

    shippedOnBoardDate: Yup.date()
      .nullable()
      .transform((curr, orig) => orig === '' ? null : curr)
      .typeError(frbValidationMessageTranslation.FrbDateTypeErrorMsg)
      .when('isCarrier', {
        is: true,
        then: Yup.date()
          .required(validationMessageTranslation.requiredMessage)
          .nullable(),
        otherwise: Yup.date().nullable()
      }),

    partnerEmails: Yup.array()
      .max(6, validationMessageTranslation.exceedEmailListLengthMsg)
      .of(
        Yup.string().max(200, frbValidationMessageTranslation.exceedEmailMaxLength)
      )
      .test(
        'partnerEmails',
        validationMessageTranslation.invalidEmailListMsg,
        (value) => validateEmailList(value)
      )
      .nullable(),

    // .required('This is required.'),

    disabledEmailAddresses: Yup.array()
  })
}

export default ContactValidationSchema
