import { BasePage as CngBasePage } from 'cng-web-lib'
import MySIPage from '../mysi/MySIPage.js'
import React from 'react'
import useBreadcrumbNameMap from 'src/navigations/breadcrumbs/Breadcrumb_ShippingInstruction'
import ShippingInstructionTranslationText from '../ShippingInstructionTranslationText'
import CreateSIViewSIDraft from 'src/views/shippinginstruction/createsi/viewsidraft/CreateSIViewSIDraftPage'
import NewSIPage from 'src/views/shippinginstruction/createsi/newsi/NewSIPage'
import ReviewSIPage from 'src/views/shippinginstruction/createsi/reviewsi/ReviewSIPage'
import SIUtils from 'src/views/shippinginstruction/shared/Utils'

function WrappedMySIPage(props) {
  const breadcrumbNameMap = useBreadcrumbNameMap()
  const translatedTextsObject = ShippingInstructionTranslationText()
  return (
    <CngBasePage
      moduleTitle={translatedTextsObject.title}
      renderPage={(showSnackbar) => (
        <MySIPage showNotification={showSnackbar} {...props} />
      )}
      extraCngBreadcrumbNameMap={breadcrumbNameMap}
    />
  )
}

function WrappedMySIViewSIPage(props) {
  const breadcrumbNameMap = useBreadcrumbNameMap()
  const translatedTextsObject = ShippingInstructionTranslationText()
  return (
    <CngBasePage
      moduleTitle={translatedTextsObject.title}
      renderPage={(showSnackbar) => (
        <CreateSIViewSIDraft showNotification={showSnackbar} {...props} />
      )}
      extraCngBreadcrumbNameMap={breadcrumbNameMap}
    />
  )
}

function WrapMySIVManageSIPage(props) {
  const breadcrumbNameMap = useBreadcrumbNameMap()
  const translatedTextsObject = ShippingInstructionTranslationText()
  return (
    <CngBasePage
      moduleTitle={translatedTextsObject.title}
      renderPage={(showSnackbar) => (
        <NewSIPage
          showNotification={showSnackbar}
          {...props}
          prevMenu={SIUtils.Menus.mySI}
        />
      )}
      extraCngBreadcrumbNameMap={breadcrumbNameMap}
    />
  )
}

function WrapMySIVManageSIReviewPage(props) {
  const breadcrumbNameMap = useBreadcrumbNameMap()
  const translatedTextsObject = ShippingInstructionTranslationText()
  return (
    <CngBasePage
      moduleTitle={translatedTextsObject.title}
      renderPage={(showSnackbar) => (
        <ReviewSIPage showNotification={showSnackbar} {...props} />
      )}
      extraCngBreadcrumbNameMap={breadcrumbNameMap}
    />
  )
}

export {
  WrappedMySIPage as MySIPage,
  WrappedMySIViewSIPage as MySIViewSIPage,
  WrapMySIVManageSIPage as MySIVManageSIPage,
  WrapMySIVManageSIReviewPage as MySIVManageSIReviewPage,
  WrapMySIVManageSIReviewPage as MySIVManageSISubmitPage
}
