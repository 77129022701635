import { Box, Button, Grid } from '@material-ui/core'

import CalistaUiComponentTranslationText from 'src/views/common/CalistaUiComponentTranslationText'
import React from 'react'
import * as calistaConstant from 'src/constants/CalistaConstant'

function MySIReviewSIButtonComponent(props) {
  const translatedTextsObject = CalistaUiComponentTranslationText()

  const isSIConfirmed =
    props.siStatus.toString() === calistaConstant.SI_STATUS_SI_CONFIRMED

  const isSIPendingConfirm =
    props.siStatus.toString() ===
    calistaConstant.SI_STATUS_PENDING_SHIPPER_CONFIRMATION

  const isSIPendingCarrierConfirm =
    props.siStatus.toString() ===
    calistaConstant.SI_STATUS_PENDING_CARRIER_CONFIRMATION

  function getLabelForBlueButton() {
    if (isSIConfirmed) return translatedTextsObject.confirm
    // if (isSIPendingConfirm ) return translatedTextsObject.confirmWithoutChange
    if (isSIPendingCarrierConfirm) return translatedTextsObject.submit

    if (isSIPendingConfirm) {
      return props.isSkipManagerApproval
        ? translatedTextsObject.apporveDischardChanges
        : translatedTextsObject.confirm
    }

    return translatedTextsObject.submitWithoutchange
  }
  return (
    <Box pl={5} pr={5} pt={4} pb={4}>
      <Grid container xs={12} sm={12}>
        <Grid xs={6} sm={6} justify='flex-start'>
          <Button
            variant='contained'
            color='secondary'
            onClick={() => {
              props.onDiscard()
            }}
            classes={{ root: 'ng-button-filled-secondary' }}
          >
            {translatedTextsObject.discard}
          </Button>
        </Grid>
        <Grid container xs={6} sm={6} justify='flex-end' spacing={2}>
          <Box pr={2}>
            <Button
              variant='contained'
              color='secondary'
              className='button-blue originalText'
              onClick={() => {
                props.onEdit()
              }}
              classes={{ root: 'ng-button-filled-secondary' }}
            >
              {translatedTextsObject.back}
            </Button>
          </Box>
          <Box pr={2}>
            <Button
              variant='contained'
              className='button-blue originalText'
              onClick={() => {
                isSIConfirmed || isSIPendingCarrierConfirm
                  ? props.onSaveDraft()
                  : props.onSubmitSI()
              }}
            >
              {getLabelForBlueButton()}
            </Button>
          </Box>
          <Box>
            {!(isSIConfirmed || isSIPendingCarrierConfirm) && (
              <Button
                variant='contained'
                className='button-light-green originalText'
                onClick={() => {
                  props.onSaveDraft()
                }}
              >
                {isSIPendingConfirm
                  ? translatedTextsObject.submitForConfirmation
                  : translatedTextsObject.submit}
              </Button>
            )}
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}

export default MySIReviewSIButtonComponent
