import { Yup } from 'cng-web-lib'
import ValidationMessageTranslationText from './ValidationMessageTranslationText'

function ReeferValidationSchema() {

    const validationMessageTranslation = ValidationMessageTranslationText()

    return Yup.object({
        equipmentTemp: Yup
            .number()
            .transform((value) => Number.isNaN(value) ? null : value)
            .required(validationMessageTranslation.requiredMessage)
            .nullable()
            .min(-98.9, "Eqiupment Temperature cannot be less than -98.9")
            .max(998.9, "Eqiupment Temperature cannot be more than 998.9"),
        tempUom: Yup.string().required(validationMessageTranslation.requiredMessage).nullable()
    })
}

export default ReeferValidationSchema