import { Yup } from 'cng-web-lib'
import * as ValidationRegex from 'src/views/shippinginstruction/shared/validation/ValidationRegex'
import ValidationMessageTranslationText from 'src/views/shippinginstruction/shared/validation/ValidationMessageTranslationText'

function PaymentValidationSchema() {
  const validationMessageTranslation = ValidationMessageTranslationText()

  return Yup.object({
    paymentLocation: Yup.string()
      .max(30, validationMessageTranslation.wordLimitExceedMsg)
      .nullable(),
    freightTerm: Yup.string()
      .required(validationMessageTranslation.requiredMessage)
      .max(30)
      .nullable(),
    payer: Yup.string()
      .required(validationMessageTranslation.requiredMessage)
      .max(30)
      .nullable(),
    chrgType: Yup.string()
      .required(validationMessageTranslation.requiredMessage)
      .max(30)
      .nullable()
  })
}

export default PaymentValidationSchema
