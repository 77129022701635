import { Yup } from 'cng-web-lib'
import * as ValidationRegex from 'src/views/shippinginstruction/shared/validation/ValidationRegex'
import ValidationMessageTranslationText from 'src/views/shippinginstruction/shared/validation/ValidationMessageTranslationText'

function ContainerValidationSchema() {
  const validationMessageTranslation = ValidationMessageTranslationText()
  let contNo_list = []

  function validateContWeightVolumeNumber(value, name) {
    if (!!value) {
      return String(value).match(ValidationRegex.weightVolumeRegx)
    } else {
      return true
    }
  }

  Yup.addMethod(Yup.array, 'uniqueList', function (message) {
    return this.test('uniqueList', message, function (val) {
      let list = []
      val.map((elem) => {
        if (list.includes(elem.containerNo)) {
          contNo_list.push(elem.containerNo)
        } else {
          list.push(elem.containerNo)
        }
        if (list.length === val.length) {
          contNo_list = []
        }
        return elem
      })
      return true
    })
  })

  Yup.addMethod(Yup.string, 'uniqueCont', function (message) {
    return this.test('uniqueCont', message, function (val) {
      // if (contNo_list.includes(val)) {
      //   return false
      // } else {
      //   return true
      // }
      return !contNo_list.includes(val)
    })
  })

  return Yup.object({
    shippingInfoContainerBoxes: Yup.array(
      Yup.object({
        containerNo: Yup.string()
          .matches(
            ValidationRegex.excludeSpecialCharRegx,
            validationMessageTranslation.invalidStrMsg
          )
          .required(validationMessageTranslation.requiredMessage)
          .max(15)
          .uniqueCont(validationMessageTranslation.contNumberUniqueMsg),
        sealNo: Yup.string()
          .nullable()
          .matches(
            ValidationRegex.excludeSpecialCharRegx,
            validationMessageTranslation.invalidStrMsg
          )
          .max(35, validationMessageTranslation.valueTooBigMsg),
        containerIsoSizeType: Yup.string()
          .required(validationMessageTranslation.requiredMessage)
          .nullable(),
        grossWeight: Yup.mixed().test(
          'grossWeight',
          validationMessageTranslation.numberErrorMsg,
          (value) => validateContWeightVolumeNumber(value)
        ),
        totalVgmWeight: Yup.mixed().test(
          'totalVgmWeight',
          validationMessageTranslation.numberErrorMsg,
          (value) => validateContWeightVolumeNumber(value)
        ),
        pkgCnt: Yup.number()
          .transform((curr, orig) => orig === '' ? null : curr)
          .integer(validationMessageTranslation.IntegerOnlyMsg)
          .nullable(),

        //Reefer validation
        equipmentTemp: Yup.number().when(["nonActiveReefer", "reeferAvailable"], {
          is: (nonActiveReefer, reeferAvailable) => !nonActiveReefer && reeferAvailable,
          then: Yup
            .number()
            .transform((value) => Number.isNaN(value) ? null : value)
            .required(validationMessageTranslation.requiredMessage)
            .nullable(),
          otherwise: Yup
            .number()
            .optional()
            .transform(value => (isNaN(value) ? undefined : value))
            .nullable(true)
        })
      })
    ).uniqueList('')
  })
}

export default ContainerValidationSchema
