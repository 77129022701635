import * as CalistaConstant from 'src/constants/CalistaConstant'
import CommonUtils from 'src/views/common/utils/Utils'

const Page = {
  BookingDetail: 'BookingDetail',
  BookingList: 'BookingList',
  SIDetail: 'SIDetail',
  SIList: 'SIList',
  PermitList: 'PermitList',
  PermitDetail: 'PermitDetail'
}

const Menus = {
  newSI: 'newSI',
  draftSI: 'draftSI',
  mySI: 'mySI',
  manageSI: 'manageSI',
  createPermit: 'createPermit',
  draftPermit: 'draftPermit',
  managePermit: 'managePermit'
}
const Action = {
  createPermit: 'createPermit',
  enquireStatus: 'enquireStatus',
  downloadPermit: 'downloadPermit',
  cancel: 'cancel',
  copy: 'copy',
  delete: 'delete',
  edit: 'edit',
  update: 'update',
  archive: 'archive',
  viewSI: 'viewSI',
  createSI: 'createSI',
  trackContainers: 'trackContainers',
  approve: 'approve',
  reject: 'reject',
  manage: 'manage',
  printbl: 'printbl',
  surrenderbl: 'surrenderbl',
  issue: 'issue',
  revoke: 'revoke',
  revokeIssuedBL: 'RevokeIssuedBL',
  confirm: 'confirm',
  updateConfirm: 'updateConfirm',
  submit: 'submit',
  updateSubmit: 'updateSubmit',
  send: 'send',
  retract: 'retract'
}

const containerType = [
  { isoType: "20' General purpose", isoCode: '22G1', containerCode: '20GP' },
  { isoType: "40' High Cube", isoCode: '45G0', containerCode: '40HC' },
  { isoType: "20' High Cube", isoCode: '25G0', containerCode: '20HC' },
  { isoType: "45' High Cube", isoCode: 'L5G0', containerCode: '45HC' },
  { isoType: "40' General purpose", isoCode: '42G1', containerCode: '40GP' },
  { isoType: "45' General purpose", isoCode: 'L2G1', containerCode: '45GP' },
  { isoType: "20' Reefer", isoCode: '22R1', containerCode: '20RF' },
  { isoType: "45' High Cube Reefer", isoCode: 'L5R1', containerCode: '45RH' },
  { isoType: "40' High Cube Reefer", isoCode: '45R1', containerCode: '40RH' },
  { isoType: "20' High Cube Reefer", isoCode: '25R1', containerCode: '20RH' },
  { isoType: "45' Reefer", isoCode: 'L2R1', containerCode: '45RF' },
  { isoType: "40' Reefer", isoCode: '42R1', containerCode: '40RF' },
  { isoType: "40' High Cube Tank", isoCode: '45T0', containerCode: '45T0' },
  { isoType: "20' High Cube Tank", isoCode: '25T0', containerCode: '25T0' },
  { isoType: "45' Tank", isoCode: 'L2T0', containerCode: '45TK' },
  { isoType: "40' Tank", isoCode: '42T0', containerCode: '40TK' },
  { isoType: "20' Tank", isoCode: '22T0', containerCode: '20TK' },
  { isoType: "45' High Cube Tank", isoCode: 'L5T0', containerCode: 'L5T0' }
]

function getContainerCodeStatusByISOCode(isoCode) {
  return containerType.find((b) => b.isoCode === isoCode)
}

const SIStatus = [
  {
    id: 5201,
    name: 'Pending SI Submission',
    color: '#5E81F44C',
    actions: [Action.update, Action.copy, Action.delete],
    actionsH: [Action.copy, Action.delete, Action.update]
  },
  {
    id: 5202,
    name: 'Pending Carrier Confirmation',
    color: '#9698D64D',
    actions: [
      Action.manage,
      Action.trackContainers,
      Action.copy,
      Action.delete
    ],
    actionsH: [
      Action.manage,
      Action.trackContainers,
      Action.copy,
      Action.delete
    ],
    carrierActions: [Action.manage, Action.cancel],
    carrierActionsH: [Action.cancel, Action.manage]
  },
  {
    id: 5209,
    name: 'Pending Shipper Confirmation',
    color: '#9698D64D',
    actions: [Action.manage, Action.trackContainers, Action.copy],
    actionsH: [Action.manage, Action.trackContainers, Action.copy],
    carrierActions: [Action.cancel],
    carrierActionsH: [Action.cancel]
  },
  {
    id: 5212,
    name: 'Pending SI Re-submission',
    color: '#5E81F44D',
    actions: [Action.manage, Action.trackContainers, Action.copy],
    actionsH: [Action.manage, Action.trackContainers, Action.copy],
    carrierActions: [Action.cancel],
    carrierActionsH: [Action.cancel]
  },
  {
    id: 5211,
    name: 'Pending SI Approval',
    color: '#F2C05B4D',
    actions: [Action.trackContainers, Action.copy],
    actionsH: [Action.trackContainers, Action.copy],
    carrierActions: [Action.retract],
    carrierActionsH: [Action.retract],
    carrierManagerActions: [Action.approve, Action.reject],
    carrierManagerActionsH: [Action.reject, Action.approve]
  },
  {
    id: 5210,
    name: 'SI Confirmed',
    color: '#F2F25B4D',
    actions: [
      Action.manage,
      Action.trackContainers,
      Action.copy,
      Action.archive
    ],
    actionsH: [
      Action.manage,
      Action.trackContainers,
      Action.copy,
      Action.archive
    ],
    carrierActions: [Action.manage],
    carrierActionsH: [Action.manage]
  },
  {
    id: 5203,
    name: 'SI Approved',
    color: '#7CE7AC4D',
    actions: [
      Action.trackContainers,
      Action.copy,
      Action.createPermit,
      Action.archive
    ],
    actionsH: [
      Action.trackContainers,
      Action.copy,
      Action.createPermit,
      Action.archive
    ],
    actionsC: [
      Action.createPermit
    ],
    carrierActions: [Action.issue, Action.revoke],
    carrierActionsH: [Action.revoke, Action.issue]
  },
  {
    id: 5301,
    name: 'B/L Issued',
    color: '#F578B24D',
    actions: [
      Action.printbl,
      Action.surrenderbl,
      Action.trackContainers,
      Action.copy,
      Action.archive
    ],
    actionsH: [
      Action.archive,
      Action.copy,
      Action.trackContainers,
      Action.printbl,
      Action.surrenderbl
    ],
    carrierActions: [Action.revokeIssuedBL],
    carrierActionsH: [Action.revokeIssuedBL]
  },
  {
    id: 5302,
    name: 'B/L Surrendered',
    color: '#8FF45E4D',
    actions: [Action.trackContainers, Action.copy, Action.archive],
    actionsH: [Action.trackContainers, Action.copy, Action.archive],
    carrierActions: [],
    carrierActionsH: []
  },
  {
    id: 5213,
    name: 'SI Cancelled',
    color: '#1C1D214D',
    actions: [Action.copy],
    actionsH: [Action.copy],
    carrierActions: [],
    carrierActionsH: []
  },
  {
    id: 5208,
    name: 'Booking Cancelled',
    color: '#1C1D214D',
    actions: [Action.copy],
    actionsH: [Action.copy],
    carrierActions: [],
    carrierActionsH: []
  }
]
const PermitSortingType = [
  { value: 1, text: "Most Recent" },
  { value: 2, text: "Port of Discharge (A-Z)" },
  { value: 3, text: "Port of Discharge (Z-A)" },
  { value: 4, text: "Release Location (A-Z)" },
  { value: 5, text: "Release Location (Z-A)" },
  { value: 6, text: "Receipt Location (A-Z)" },
  { value: 7, text: "Receipt Location (Z-A)" }
]

const PermitStatus = [
  {
    id: 5401,
    name: 'Pending Submission',
    color: '#8996AF41',
    actions: [Action.update, Action.delete],
    actionsH: [Action.update, Action.delete]
  },
  {
    id: 5402,
    name: 'Permit Created',
    color: '#5E81F44C',
    actions: [Action.update, Action.enquireStatus],
    actionsH: [Action.update, Action.enquireStatus]
  },
  {
    id: 5403,
    name: 'Permit Submitted',
    color: '#F2C05B4D',
    actions: [Action.enquireStatus],
    actionsH: [Action.enquireStatus]
  },
  {
    id: 5404,
    name: 'Permit Approved',
    color: '#7CE7AC4D',
    actions: [Action.enquireStatus, Action.update, Action.downloadPermit],
    actionsH: [Action.enquireStatus, Action.update, Action.downloadPermit]
  },
  {
    id: 5405,
    name: 'Permit Rejected',
    color: '#FF808B4D',
    actions: [Action.enquireStatus],
    actionsH: [Action.enquireStatus]
  },
  {
    id: 5406,
    name: 'Amendment Created',
    color: '#5E81F44C',
    actions: [Action.update, Action.enquireStatus],
    actionsH: [Action.update, Action.enquireStatus]
  },
  {
    id: 5407,
    name: 'Amendment Submitted',
    color: '#F2C05B4D',
    actions: [Action.enquireStatus],
    actionsH: [Action.enquireStatus]
  }
]
function getPermitStatusById(id) {
  return PermitStatus.find((b) => b.id === id)
}
function getSIStatusById(id) {
  return SIStatus.find((b) => b.id === id)
}
function getSIStatusByName(name) {
  return SIStatus.find((b) => b.name.toUpperCase() === name.toUpperCase())
}

const AmendButtonShowStatus = [
  CalistaConstant.BOOKING_STATUS_AMENDED,
  CalistaConstant.BOOKING_STATUS_APPROVED,
  CalistaConstant.BOOKING_STATUS_PENDING_APPROVAL
]

const ApproveButtonShowStatus = [
  CalistaConstant.BOOKING_STATUS_PENDING_APPROVAL,
  CalistaConstant.BOOKING_STATUS_AMENDED
]

const RejectButtonShowStatus = [
  CalistaConstant.BOOKING_STATUS_PENDING_APPROVAL,
  CalistaConstant.BOOKING_STATUS_AMENDED
]

function getShowButtonForCarrier(bookingStatus) {
  return {
    showApprove: ApproveButtonShowStatus.includes(bookingStatus + '')
      ? ''
      : 'none',
    showReject: RejectButtonShowStatus.includes(bookingStatus + '')
      ? ''
      : 'none',
    showAmend: AmendButtonShowStatus.includes(bookingStatus + '') ? '' : 'none'
  }
}

function isEmpty(obj) {
  return Object.keys(obj).length === 0
}

const maxContainerNo = 1000

const dateTimeFields = [
  'polEta',
  'polEtd',
  'podEta',
  'podEtd',
  'emptyPickupDatePickup',
  'shipFromDatePositioning',
  'shipFromDatePickup',
  'shipToDateDelivery',
  'placeOfReceiptEtd',
  'placeOfDeliveryEta',
  'shippedOnBoardDate',
  'issueDate'
]

const dateFields = [
  'cargoReadyDate',
  'cargoDeliveryDate',
  'placeOfReceiptEtd',
  'placeOfDeliveryEta',
  'deptDate'
]

const nonNullableArrayFields = [
  'shippingInfoCargoes',
  'shippingInfoDGCargoes',
  'pendingShippingInfoDocuments',
  'shippingInfoDocuments',
  'partnerEmails',
  'shippingInfoUploadDTO',
  'shippingInfoContainerBoxes'
]

function convertDate(data) {
  const dataArr = Object.entries(data)
  dataArr.map(([key, val]) => {
    if (dateTimeFields.includes(key)) {
      var dateToServer = CommonUtils.strictFormatDateAndConvertToGMT(
        val,
        CommonUtils.UI_FORMAT_DATE_TIME,
        null
      )

      data[key] = dateToServer
    } else if (dateFields.includes(key)) {
      if (val != null && val !== '') {
        data[key] = CommonUtils.strictFormatDateAndConvertToGMT(
          val,
          CommonUtils.UI_FORMAT_DATE,
          null
        )
      }
    }

    return data
  })
}

function expandBookingToShippingInfo(shippingInfo) {
  /**
   * when get si details by id, the booking will be a subset of shippingInfo, which is not
   * expected in review / edit page
   * hence this function is to populate the value under shippingInfo.booking to shippingInfo
   */
  shippingInfo.carrierRefNo = shippingInfo.booking?.carrierRefNo
  shippingInfo.forwardHubScheme = shippingInfo.booking?.forwardHubScheme
  shippingInfo.forwardHubUserGroup = shippingInfo.booking?.forwardHubUserGroup
  shippingInfo.podEta = shippingInfo.booking?.podEta
  shippingInfo.podEtd = shippingInfo.booking?.podEtd
  shippingInfo.polEta = shippingInfo.booking?.polEta
  shippingInfo.polEtd = shippingInfo.booking?.polEtd
  shippingInfo.representativeContactNo =
    shippingInfo.booking?.representativeContactNo
  shippingInfo.representativeEmail = shippingInfo.booking?.representativeEmail
  shippingInfo.representativeName = shippingInfo.booking?.representativeName
  shippingInfo.commonRef = shippingInfo.booking?.commonRef
  shippingInfo.intVoyageNo = shippingInfo.booking?.intVoyageNo
  shippingInfo.placeOfDeliveryCode = shippingInfo.booking?.placeOfDeliveryCode
  shippingInfo.placeOfReceiptCode = shippingInfo.booking?.placeOfReceiptCode
  shippingInfo.placeOfDeliveryEta = shippingInfo.booking?.placeOfDeliveryEta
  shippingInfo.placeOfReceiptEtd = shippingInfo.booking?.placeOfReceiptEtd
  shippingInfo.destCode = shippingInfo.booking?.destCode
  shippingInfo.bookingCarrierRemarks = shippingInfo.booking?.carrierRemarks
  shippingInfo.bookingShipperRemarks = shippingInfo.booking?.shipperRemarks
  shippingInfo.contIsoTypeList = shippingInfo.booking?.contIsoTypeList
  shippingInfo.maxContainerNo = shippingInfo.booking?.maxContainerNo
  return shippingInfo
}

const carrierRole = {
  MANAGER: 'MANAGER',
  CLERK: 'CLERK',
  BOTH: 'BOTH'
}

function checkCarrierRole(roleCodes) {
  if (
    roleCodes.some((substring) => substring.includes(carrierRole.CLERK)) &&
    roleCodes.some((substring) => substring.includes(carrierRole.MANAGER))
  ) {
    return carrierRole.BOTH
  } else if (
    roleCodes.some((substring) => substring.includes(carrierRole.MANAGER))
  ) {
    return carrierRole.MANAGER
  } else {
    return carrierRole.CLERK
  }
}

function isNotDirectSiDraft(prevMenu, isDirectSI) {
  return !(
    (Menus.newSI === prevMenu || Menus.draftSI === prevMenu) &&
    isDirectSI
  )
}

function isNotDirectSiCarrier(isCarrier, isDirectSI) {
  return !(isDirectSI && isCarrier)
}

function auditHighLightContainer(
  containerCompares,
  currentContainers,
  containerTypes
) {
  console.log('hl containerCompares', containerCompares)
  console.log('hl currentContainers', currentContainers)
  console.log('hl containerTypes', containerTypes)
  let containerList = []

  if (CommonUtils.isNotEmpty(currentContainers)) {
    currentContainers.forEach((container) => {
      let containerAdded =
        containerCompares[CommonUtils.ADDED] &&
        containerCompares[CommonUtils.ADDED].find(
          (c) => c.id === container.contBoxId
        )
      if (containerAdded) {
        // return {
        //   container: CommonUtils.highLighted
        // }
        containerList.push({
          id: container.contBoxId,
          container: CommonUtils.highLighted
        })
      }
      let containerUpdated =
        containerCompares[CommonUtils.UPDATED] &&
        containerCompares[CommonUtils.UPDATED].find(
          (c) => c.id === container.contBoxId
        )
      if (containerUpdated) {
        let contNo = CommonUtils.auditHighLight(containerUpdated, ['contNo'])
        let pkgType = CommonUtils.auditHighLight(containerUpdated, ['pkgType'])
        let sealNo = CommonUtils.auditHighLight(containerUpdated, ['sealNo'])
        let specialHandling = CommonUtils.auditHighLight(containerUpdated, [
          'specialHandling'
        ])
        let stowage = CommonUtils.auditHighLight(containerUpdated, ['stowage'])
        let tareWeight = CommonUtils.auditHighLight(containerUpdated, [
          'tareWeight'
        ])
        let vgmWeight = CommonUtils.auditHighLight(containerUpdated, [
          'vgmWeight'
        ])

        let isoTypeChanges = CommonUtils.getAuditTrailDiffs(containerUpdated, [
          'isoSizeType'
        ])
        let contIsoType = {},
          contHeightDesc = {},
          contTypeDesc = {},
          contSize = {}
        if (CommonUtils.hasChanges(isoTypeChanges)) {
          console.log('hl has changes')
          contIsoType = CommonUtils.highLighted
          let matchedNew = containerTypes.find(
            (updateContainer) =>
              updateContainer?.isoCode === isoTypeChanges.valueNew[0]
          )
          let matchedOld = containerTypes.find(
            (updateContainer) =>
              updateContainer?.isoCode === isoTypeChanges.valueOld[0]
          )
          console.log('hl match new', matchedNew)
          console.log('hl match old', matchedOld)
          if (matchedNew && matchedOld) {
            contSize =
              matchedNew.contSize == matchedOld.contSize
                ? {}
                : CommonUtils.highLighted
            contTypeDesc =
              matchedNew.contTypeDesc == matchedOld.contTypeDesc
                ? {}
                : CommonUtils.highLighted
            contHeightDesc =
              matchedNew.contHeightDesc == matchedOld.contHeightDesc
                ? {}
                : CommonUtils.highLighted
          }
        }
        containerList.push({
          id: container.contBoxId,
          contNo: contNo,
          contIsoType: contIsoType,
          contHeightDesc: contHeightDesc,
          contTypeDesc: contTypeDesc,
          contSize: contSize,
          pkgType: pkgType,
          sealNo: sealNo,
          specialHandling: specialHandling,
          stowage: stowage,
          tareWeight: tareWeight,
          vgmWeight: vgmWeight
        })
      }
    })
  }

  return containerList
}

function auditHighLightCargo(cargoCompares, currentCargos) {
  console.log('hl cargoCompares', cargoCompares)
  console.log('hl currentCargos', currentCargos)

  let cargoList = []

  if (CommonUtils.isNotEmpty(currentCargos)) {
    currentCargos.forEach((cargo) => {
      let cargoAdded =
        cargoCompares[CommonUtils.ADDED] &&
        cargoCompares[CommonUtils.ADDED].find((c) => c.id === cargo.cargoId)
      if (cargoAdded) {
        cargoList.push({
          id: cargo.cargoId,
          cargo: CommonUtils.highLighted
        })
      }
      let cargoUpdated =
        cargoCompares[CommonUtils.UPDATED] &&
        cargoCompares[CommonUtils.UPDATED].find((c) => c.id === cargo.cargoId)
      if (cargoUpdated) {
        let containerNo = CommonUtils.auditHighLight(cargoUpdated, [
          'containerNo'
        ])
        let cargoDesc = CommonUtils.auditHighLight(cargoUpdated, ['cargoDesc'])
        let grossVolume = CommonUtils.auditHighLight(cargoUpdated, [
          'grossVolume'
        ])
        let grossWeight = CommonUtils.auditHighLight(cargoUpdated, [
          'grossWeight'
        ])
        let height = CommonUtils.auditHighLight(cargoUpdated, ['height'])
        let hsCode = CommonUtils.auditHighLight(cargoUpdated, ['hsCode'])
        let length = CommonUtils.auditHighLight(cargoUpdated, ['length'])

        let marksNNo = CommonUtils.auditHighLight(cargoUpdated, ['marksNNo'])

        let netWeight = CommonUtils.auditHighLight(cargoUpdated, ['netWeight'])
        let packageType = CommonUtils.auditHighLight(cargoUpdated, [
          'packageType'
        ])
        let quantity = CommonUtils.auditHighLight(cargoUpdated, ['quantity'])
        let uom = CommonUtils.auditHighLight(cargoUpdated, ['uom'])
        let width = CommonUtils.auditHighLight(cargoUpdated, ['width'])

        cargoList.push({
          id: cargo.cargoId,

          cargoDesc: cargoDesc,
          containerNo: containerNo,
          grossVolume: grossVolume,
          grossWeight: grossWeight,
          height: height,
          hsCode: hsCode,
          length: length,
          marksNNo: marksNNo,
          netWeight: netWeight,
          packageType: packageType,
          quantity: quantity,
          uom: uom,
          width: width
        })
      }
    })
  }

  return cargoList
}

function auditHighLightDGCargo(cargoCompares, currentCargos) {
  console.log('hl cargdgoCompares', cargoCompares)
  console.log('hl currentdgCargos', currentCargos)

  let cargoList = []

  if (CommonUtils.isNotEmpty(currentCargos)) {
    currentCargos.forEach((cargo) => {
      let cargoAdded =
        cargoCompares[CommonUtils.ADDED] &&
        cargoCompares[CommonUtils.ADDED].find((c) => c.id === cargo.dgCargoId)
      if (cargoAdded) {
        console.log('dgcargo add', cargoAdded)
        cargoList.push({
          id: cargo.dgCargoId,
          cargo: CommonUtils.highLighted,
          dgDetails: cargoAdded.added.shippingInfoDGCargoDetDTOs
        })
      }
      let cargoUpdated =
        cargoCompares[CommonUtils.UPDATED] &&
        cargoCompares[CommonUtils.UPDATED].find((c) => c.id === cargo.dgCargoId)
      console.log('dgcargo update', cargoUpdated)
      if (cargoUpdated) {
        let containerNo = CommonUtils.auditHighLight(cargoUpdated, [
          'containerNo'
        ])
        let description = CommonUtils.auditHighLight(cargoUpdated, [
          'description'
        ])
        let totalGrossVolume = CommonUtils.auditHighLight(cargoUpdated, [
          'totalGrossVolume'
        ])
        let totalGrossWeight = CommonUtils.auditHighLight(cargoUpdated, [
          'totalGrossWeight'
        ])
        let totalNetVolume = CommonUtils.auditHighLight(cargoUpdated, [
          'totalNetVolume'
        ])

        let totalNetWeight = CommonUtils.auditHighLight(cargoUpdated, [
          'totalNetWeight'
        ])

        let totalVgmWeight = CommonUtils.auditHighLight(cargoUpdated, [
          'totalVgmWeight'
        ])
        let hsCode = CommonUtils.auditHighLight(cargoUpdated, ['hsCode'])

        let packageType = CommonUtils.auditHighLight(cargoUpdated, [
          'packageType'
        ])
        let quantity = CommonUtils.auditHighLight(cargoUpdated, ['quantity'])

        cargoList.push({
          id: cargo.dgCargoId,
          description: description,
          containerNo: containerNo,
          totalGrossVolume: totalGrossVolume,
          totalGrossWeight: totalGrossWeight,
          totalNetVolume: totalNetVolume,
          totalNetWeight: totalNetWeight,
          totalVgmWeight: totalVgmWeight,
          hsCode: hsCode,
          packageType: packageType,
          quantity: quantity,
          dgDetails: cargoUpdated._changes_.shippingInfoDGCargoDetDTOs
        })
      }
    })
  }

  return cargoList
}

function auditHighLightDGCargoDetails(cargoDetailsCompares) {
  let undgNumber = CommonUtils.auditHighLight(cargoDetailsCompares, [
    'undgNumber'
  ])
  let acidConcentration = CommonUtils.auditHighLight(cargoDetailsCompares, [
    'acidConcentration'
  ])
  let aggregationState = CommonUtils.auditHighLight(cargoDetailsCompares, [
    'aggregationState'
  ])
  let amountOfFumigation = CommonUtils.auditHighLight(cargoDetailsCompares, [
    'amountOfFumigation'
  ])
  let boilingPoint = CommonUtils.auditHighLight(cargoDetailsCompares, [
    'boilingPoint'
  ])
  let boilingPointUom = CommonUtils.auditHighLight(cargoDetailsCompares, [
    'boilingPointUom'
  ])
  let controlTemperature = CommonUtils.auditHighLight(cargoDetailsCompares, [
    'controlTemperature'
  ])
  let controlTemperatureUom = CommonUtils.auditHighLight(cargoDetailsCompares, [
    'controlTemperatureUom'
  ])
  let dateOfFumigation = CommonUtils.auditHighLight(cargoDetailsCompares, [
    'dateOfFumigation'
  ])
  let emergencyContactName = CommonUtils.auditHighLight(cargoDetailsCompares, [
    'emergencyContactName'
  ])

  let emergencyContactNumber = CommonUtils.auditHighLight(
    cargoDetailsCompares,
    ['emergencyContactNumber']
  )
  let emergencyTemperature = CommonUtils.auditHighLight(cargoDetailsCompares, [
    'emergencyTemperature'
  ])
  let emergencyTemperatureUom = CommonUtils.auditHighLight(
    cargoDetailsCompares,
    ['emergencyTemperatureUom']
  )
  let emsNumber = CommonUtils.auditHighLight(cargoDetailsCompares, [
    'emsNumber'
  ])

  let exceptedQty = CommonUtils.auditHighLight(cargoDetailsCompares, [
    'exceptedQty'
  ])
  let exceptedQtyUom = CommonUtils.auditHighLight(cargoDetailsCompares, [
    'exceptedQtyUom'
  ])
  let flashpoint = CommonUtils.auditHighLight(cargoDetailsCompares, [
    'flashpoint'
  ])
  let flashpointUom = CommonUtils.auditHighLight(cargoDetailsCompares, [
    'flashpointUom'
  ])

  let fumigationType = CommonUtils.auditHighLight(cargoDetailsCompares, [
    'fumigationType'
  ])
  let generalComments = CommonUtils.auditHighLight(cargoDetailsCompares, [
    'generalComments'
  ])
  let grossVolume = CommonUtils.auditHighLight(cargoDetailsCompares, [
    'grossVolume'
  ])
  let grossWeight = CommonUtils.auditHighLight(cargoDetailsCompares, [
    'grossWeight'
  ])

  let ibcPackageCode = CommonUtils.auditHighLight(cargoDetailsCompares, [
    'ibcPackageCode'
  ])
  let imdgCodePage = CommonUtils.auditHighLight(cargoDetailsCompares, [
    'imdgCodePage'
  ])
  let imoClass = CommonUtils.auditHighLight(cargoDetailsCompares, ['imoClass'])
  let inhalantHazard = CommonUtils.auditHighLight(cargoDetailsCompares, [
    'inhalantHazard'
  ])

  let innerPkgQtyUom = CommonUtils.auditHighLight(cargoDetailsCompares, [
    'innerPkgQtyUom'
  ])
  let limitedQty = CommonUtils.auditHighLight(cargoDetailsCompares, [
    'limitedQty'
  ])
  let limitedQtyUom = CommonUtils.auditHighLight(cargoDetailsCompares, [
    'limitedQtyUom'
  ])
  let marinePollutantStatus = CommonUtils.auditHighLight(cargoDetailsCompares, [
    'marinePollutantStatus'
  ])

  let netVolume = CommonUtils.auditHighLight(cargoDetailsCompares, [
    'netVolume'
  ])
  let netWeight = CommonUtils.auditHighLight(cargoDetailsCompares, [
    'netWeight'
  ])
  let outerPkgQty = CommonUtils.auditHighLight(cargoDetailsCompares, [
    'outerPkgQty'
  ])
  let outerPkgQtyUom = CommonUtils.auditHighLight(cargoDetailsCompares, [
    'outerPkgQtyUom'
  ])

  let packingDetails = CommonUtils.auditHighLight(cargoDetailsCompares, [
    'packingDetails'
  ])
  let packingGroupCode = CommonUtils.auditHighLight(cargoDetailsCompares, [
    'packingGroupCode'
  ])
  let placardInformation = CommonUtils.auditHighLight(cargoDetailsCompares, [
    'placardInformation'
  ])
  let poisonInhalationHazardZone = CommonUtils.auditHighLight(
    cargoDetailsCompares,
    ['poisonInhalationHazardZone']
  )

  let properShippingName = CommonUtils.auditHighLight(cargoDetailsCompares, [
    'properShippingName'
  ])
  let quantity = CommonUtils.auditHighLight(cargoDetailsCompares, ['quantity'])
  let radioactiveGoodsInfo = CommonUtils.auditHighLight(cargoDetailsCompares, [
    'radioactiveGoodsInfo'
  ])
  let radioactiveMeasurement = CommonUtils.auditHighLight(
    cargoDetailsCompares,
    ['radioactiveMeasurement']
  )

  let regulationPageNumber = CommonUtils.auditHighLight(cargoDetailsCompares, [
    'regulationPageNumber'
  ])
  let regulationVersionNumber = CommonUtils.auditHighLight(
    cargoDetailsCompares,
    ['regulationVersionNumber']
  )
  let regulatoryInfomation = CommonUtils.auditHighLight(cargoDetailsCompares, [
    'regulatoryInfomation'
  ])
  let reportableQty = CommonUtils.auditHighLight(cargoDetailsCompares, [
    'reportableQty'
  ])

  let sadt = CommonUtils.auditHighLight(cargoDetailsCompares, ['sadt'])
  let sadtUom = CommonUtils.auditHighLight(cargoDetailsCompares, ['sadtUom'])
  let technicalName = CommonUtils.auditHighLight(cargoDetailsCompares, [
    'technicalName'
  ])
  let tremCardNumber = CommonUtils.auditHighLight(cargoDetailsCompares, [
    'tremCardNumber'
  ])
  let vgmWeight = CommonUtils.auditHighLight(cargoDetailsCompares, [
    'vgmWeightit'
  ])

  let emptyUncleanedRecept = CommonUtils.auditHighLight(cargoDetailsCompares, [
    'emptyUncleanedRecept'
  ])
  let netExplosiveContent = CommonUtils.auditHighLight(cargoDetailsCompares, [
    'netExplosiveContent'
  ])
  let transportDgLimitQuant = CommonUtils.auditHighLight(cargoDetailsCompares, [
    'transportDgLimitQuant'
  ])
  let weightKgs = CommonUtils.auditHighLight(cargoDetailsCompares, [
    'weightKgs'
  ])
  let weightLbs = CommonUtils.auditHighLight(cargoDetailsCompares, [
    'weightLbs'
  ])

  let cargoDetailsAudit = {
    undgNumber: undgNumber,
    acidConcentration: acidConcentration,
    aggregationState: aggregationState,
    amountOfFumigation: amountOfFumigation,
    boilingPoint: boilingPoint,
    boilingPointUom: boilingPointUom,
    controlTemperature: controlTemperature,
    controlTemperatureUom: controlTemperatureUom,
    dateOfFumigation: dateOfFumigation,
    emergencyContactName: emergencyContactName,
    emergencyContactNumber: emergencyContactNumber,
    emergencyTemperature: emergencyTemperature,
    emergencyTemperatureUom: emergencyTemperatureUom,
    emsNumber: emsNumber,
    exceptedQty: exceptedQty,
    exceptedQtyUom: exceptedQtyUom,
    flashpoint: flashpoint,
    flashpointUom: flashpointUom,
    fumigationType: fumigationType,
    generalComments: generalComments,
    grossVolume: grossVolume,
    grossWeight: grossWeight,
    ibcPackageCode: ibcPackageCode,
    imdgCodePage: imdgCodePage,
    imoClass: imoClass,
    inhalantHazard: inhalantHazard,
    innerPkgQtyUom: innerPkgQtyUom,
    limitedQty: limitedQty,
    limitedQtyUom: limitedQtyUom,
    marinePollutantStatus: marinePollutantStatus,
    netVolume: netVolume,
    netWeight: netWeight,
    outerPkgQty: outerPkgQty,
    outerPkgQtyUom: outerPkgQtyUom,
    packingDetails: packingDetails,
    packingGroupCode: packingGroupCode,
    placardInformation: placardInformation,
    poisonInhalationHazardZone: poisonInhalationHazardZone,
    properShippingName: properShippingName,
    quantity: quantity,
    radioactiveGoodsInfo: radioactiveGoodsInfo,
    radioactiveMeasurement: radioactiveMeasurement,
    properShippingName: properShippingName,
    quantity: quantity,
    radioactiveGoodsInfo: radioactiveGoodsInfo,
    radioactiveMeasurement: radioactiveMeasurement,
    regulationPageNumber: regulationPageNumber,
    regulationVersionNumber: regulationVersionNumber,
    regulatoryInfomation: regulatoryInfomation,
    reportableQty: reportableQty,
    sadt: sadt,
    sadtUom: sadtUom,
    technicalName: technicalName,
    tremCardNumber: tremCardNumber,
    vgmWeight: vgmWeight,
    emptyUncleanedRecept: emptyUncleanedRecept,
    netExplosiveContent: netExplosiveContent,
    transportDgLimitQuant: transportDgLimitQuant,
    weightKgs: weightKgs,
    weightLbs: weightLbs
  }

  return cargoDetailsAudit
}

function isThisPartyPIL(carrierName) {
  return (
    carrierName === 'PIL Test Carrier' ||
    (CommonUtils.isNotEmpty(carrierName) &&
      carrierName.toUpperCase().replace(/\s+/g, '') ===
      'PACIFICINTERNATIONALLINES(PTE)LTD')
  )
}

function calculateTotalWeightVolume(input) {
  const list = []

  if (input != null) {
    input.forEach((dgcargo, id) => {
      let newTotalGrossWeight = 0
      let newTotalNetWeight = 0
      let newTotalGrossVolume = 0
      let newTotalNetVolume = 0
      let newQuantity = 0

      if (dgcargo != null && dgcargo.dgDetails != null) {
        dgcargo.dgDetails.forEach((dgdetails) => {
          newTotalNetWeight += parseNumber(dgdetails.netWeight)
          newTotalGrossVolume += parseNumber(dgdetails.grossVolume)
          newTotalGrossWeight += parseNumber(dgdetails.grossWeight)
          newTotalNetVolume += parseNumber(dgdetails.netVolume)
          newQuantity += parseNumber(dgdetails.quantity)
        })
      }

      dgcargo.totalNetWeight = newTotalNetWeight
      dgcargo.totalGrossVolume = newTotalGrossVolume
      dgcargo.totalNetVolume = newTotalNetVolume
      dgcargo.totalGrossWeight = newTotalGrossWeight
      dgcargo.quantity = newQuantity

      list.push(dgcargo)
    })
  }
  return list
}

function parseNumber(number) {
  if (CommonUtils.isNull(number)) {
    return 0
  } else if (typeof number === 'number') {
    return number
  } else {
    return Number(number)
  }
}

function generateContainerWords(input, noOfContainer, label) {
  var words = input
  console.log('generateContainerWords', noOfContainer)
  const AND_WORD = ' AND '
  const CONTAINERS_IN_WORD = 'CONTAINERS'
  const CONTAINER_IN_WORD = 'CONTAINER'
  if (noOfContainer > 0) {
    if (words.length > 0) {
      words += AND_WORD
    }
    words = words + NumToWord(noOfContainer) + label
    if (noOfContainer > 1) {
      words += CONTAINERS_IN_WORD
    } else {
      words += CONTAINER_IN_WORD
    }
    return words
  }
}

function NumToWord(inputNumber) {
  const UNITS = new Array(
    '',
    'ONE',
    'TWO',
    'THREE',
    'FOUR',
    'FIVE',
    'SIX',
    'SEVEN',
    'EIGHT',
    'NINE',
    'TEN',
    'ELEVEN',
    'TWELVE',
    'THIRTEEN',
    'FOURTEEN',
    'FIFTEEN',
    'SIXTEEN',
    'SEVENTEEN',
    'EIGHTEEN',
    'NINETEEN'
  )
  const TENS = new Array(
    '',
    'TEN',
    'TWENTY',
    'THIRTY',
    'FORTY',
    'FIFTY',
    'SIXTY',
    'SEVENTY',
    'EIGHTY',
    'NINETY'
  )
  if (inputNumber < 20 && inputNumber >= 0) {
    return UNITS[inputNumber]
  }
  if (inputNumber < 100) {
    var tens = Math.floor(inputNumber / 10)

    var tensR = inputNumber % 10

    return TENS[tens] + isSpaceNeeded(inputNumber, 10) + UNITS[tensR]
  }

  if (inputNumber < 1000) {
    var hunds = Math.floor(inputNumber / 100)

    var hundsR = inputNumber % 100

    return (
      UNITS[hunds] +
      ' HUNDRED' +
      isSpaceNeeded(inputNumber, 100) +
      NumToWord(hundsR)
    )
  }
}

function isSpaceNeeded(num, modules) {
  return num % modules != 0 ? ' ' : ''
}

function ableToCreatePermit(si) {
  //only allow create permit if pol /place of receipt is singapore (sgsin)
  return ('SGSIN' == si.placeOfReceiptCode || 'SGSIN' == si.placeOfDeliveryCode) && !si.hazardous
}


const Utils = Object.freeze({
  Page,
  Menus,
  Action,
  SIStatus,
  dateTimeFields,
  dateFields,
  nonNullableArrayFields,
  AmendButtonShowStatus,
  containerType,
  maxContainerNo,
  carrierRole,
  getSIStatusById,
  getSIStatusByName,
  isEmpty,
  convertDate,
  getShowButtonForCarrier,
  getContainerCodeStatusByISOCode,
  expandBookingToShippingInfo,
  checkCarrierRole,
  isNotDirectSiDraft,
  isNotDirectSiCarrier,
  auditHighLightContainer,
  auditHighLightCargo,
  auditHighLightDGCargo,
  auditHighLightDGCargoDetails,
  isThisPartyPIL,
  calculateTotalWeightVolume,
  generateContainerWords,
  getPermitStatusById,
  PermitSortingType,
  ableToCreatePermit
})
export default Utils
