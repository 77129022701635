import * as CalistaConstant from 'src/constants/CalistaConstant'

import {
  Box,
  Card,
  CardContent,
  Fab,
  Grid,
  Toolbar
} from '@material-ui/core'
import { Yup, components, useServices } from 'cng-web-lib'
import React, { useEffect, useState } from 'react'

import Api from 'src/views/freightbooking/shared/api'
import ApiParser from 'src/views/freightbooking/shared/apiParser'
import ButtonTabComponent from '../components/ButtonTabComponent'
import CngBackdrop from 'src/views/vesselschedule/searchschedule/cngcomponent/CngBackDrop'
import FreeTextSearchComponent from 'src/views/shippinginstruction/components/FreeTextSearchComponent'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import SIApi from 'src/views/shippinginstruction/shared/api'
import SIFilterPanelComponent from '../components/SIFilterPanelComponent'
import SIListComponent from '../components/SIListComponent'
import ScrollTop from 'src/views/freightbooking/components/ScrollTop'
import SiApiParser from 'src/views/shippinginstruction/shared/apiParser'
import Utils from 'src/views/common/utils/Utils'
import { useLocation } from 'react-router-dom'

const {
  form: {
    adapter: {
      useFormAdapter: { useField }
    }
  },
  CngGridItem
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
  dateRange: 'all',
  pendingClicked: true,
  filterText: '',
  sortCode: '',
  pendingStatusCount: 0,
  processedStatusCount: 0,
  statusCodes: [
    CalistaConstant.SI_STATUS_PENDING_CARRIER_CONFIRMATION,
    CalistaConstant.SI_STATUS_SI_APPROVED,
    CalistaConstant.SI_STATUS_PENDING_SHIPPER_CONFIRMATION,
    CalistaConstant.SI_STATUS_SI_CONFIRMED,
    CalistaConstant.SI_STATUS_PENDING_SI_APPROVED,
    CalistaConstant.SI_STATUS_PENDING_SI_RESUBMISSION,
    CalistaConstant.SI_STATUS_SI_CANCELLED,
    CalistaConstant.SI_STATUS_BL_ISSUED,
    CalistaConstant.SI_STATUS_BL_SURRENDERED,
    CalistaConstant.SI_STATUS_BOOKING_CANCELLED

  ],
  status: {},
  bls: {},
  partyIds: [],
  carriers: {},
  showArchived: true,
  showDirectSI: false,
  statusTabChanged: true,
  blAppTypeCodes: ['SI_SB', 'SI_BL']
})

function DEFAULT_VALIDATION_SCHEMA(translate) {
  return Yup.object({})
}

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: DEFAULT_VALIDATION_SCHEMA
}

function FormFields({ showNotification, isViewSi }) {

  const { fetchRecords } = useServices()
  const location = useLocation()
  const [fromBookingPage, setFromBookingPage] = useState(
    location.state && location.state.fromMyBooking
  )

  let initialBookingID = ''

  if (fromBookingPage) {
    if (location.state.booking.dockey) {
      initialBookingID = location.state.booking.dockey
    } else {
      initialBookingID = location.state.booking.freightBookingId
    }
  }

  const searchBookingID = useState(initialBookingID)

  const [statusCodesField, , { setValue: setStatusCodesField }] =
    useField('statusCodes')
  const [blAppTypeCodesField, , { setValue: setBlAppTypeCodesField }] =
    useField('blAppTypeCodes')
  const [statusField, , { setValue: setStatusField }] = useField('status')
  const [sortCodeField, , { setValue: setSortCodeField }] = useField('sortCode')
  const [, , { setValue: setSortByListField }] = useField('sortByList')

  const [filterTextField, , { setValue: setFilterTextField }] =
    useField('filterText')

  const [carriersField, , { setValue: setCarriersField }] = useField('carriers')
  const [showArchivedField, , { setValue: setShowArchivedField }] =
    useField('showArchived')
  const [showDirectSIField, , { setValue: setShowDirectSIField }] =
    useField('showDirectSI')
  const [partyIdsField, , { setValue: setPartyIdsField }] = useField('partyIds')
  const [statusTabChangedField, , { setValue: setStatusTabChanged }] =
    useField('statusTabChanged')
  const [blsField, , { setValue: setBlsField }] = useField('bls')
  const [dateRangeField, , { setValue: setDateRangeField }] =
    useField('dateRange')

  const defaultDateRange = 'all'
  const VALUE_ALL = 'ALL'
  const defaultShowArchived = true
  const defaultShowDirectSI = false

  const [, setDateRange] = useState()
  const [sortByList, setSortByList] = useState()
  const [filterText, setFilterText] = useState()
  const [siList, setSiList] = useState([])
  const [showArchived, setShowArchived] = useState()
  const [showDirectSI, setShowDirectSI] = useState()
  const [loading, setLoading] = useState(true)
  const [shownCount, setShownCount] = useState(0)
  const [totalCount, setTotalCount] = useState(0)

  const manualRefresh = () => {
    setRefresh(!refresh)
    setLoading(true)
    setSiList([])
    setShownCount(0)
  }

  const handleSortByChange = (e) => {
    console.log('handleSortByChange')
    if (e.target.value != null && e.target.value != 0) {
      setSortCodeField(e.target.value)
      setStatusTabChanged(false)
      manualRefresh()
    }
  }

  const handelFreeTextSearch = () => {
    setStatusTabChanged(true)
    manualRefresh()
  }

  const handleClearFiltreAndTextButtonClick = () => {
    console.log('handleClearFiltreAndTextButtonClick')

    setFilterText('')
    setFilterTextField('')
    handleClearAllButtonClick()
  }
  const handleClearAllButtonClick = () => {
    console.log('handleClearAllButtonClick')

    setDateRange(defaultDateRange)
    setDateRangeField(defaultDateRange)
    let newStatus = [...status]
    newStatus = newStatus.map((item) => ({
      ...item,
      checked: item.eligible
    }))

    console.log(newStatus)
    setStatus(newStatus)
    setStatusField(newStatus)

    let newCarriers = [...carriers]
    newCarriers = newCarriers.map((item) => ({
      ...item,
      checked: true
    }))

    setCarriers(newCarriers)
    setCarriersField(newCarriers)

    setBlsField([])
    setBlAppTypeCodesField(DEFAULT_INITIAL_VALUES.blAppTypeCodes)

    setShowArchivedField(defaultShowArchived)
    setShowArchived(defaultShowArchived)

    setShowDirectSIField(defaultShowDirectSI)
    setShowDirectSI(defaultShowDirectSI)

    let newStatusCode = getNewStatuCode(newStatus)
    console.log(newStatusCode)

    setStatusCodesField(newStatusCode)

    setStatusTabChanged(true)
    manualRefresh()
  }

  const dateRangeChange = (e) => {
    console.log(e.target.defaultValue)
    setDateRange(e.target.defaultValue)
    setDateRangeField(e.target.defaultValue)

    manualRefresh()
  }

  const handleFilterTextChange = (e) => {
    console.log(e.target)
    setFilterText(e.target.value)
    setFilterTextField(e.target.value)
  }

  const onStatusCheckBoxChange =
    (index) =>
      ({ target: { checked } }) => {
        console.log('handleStatusCheckBoxChange' + index)
        let newStatus = [...status]
        const currentStatus = status[index]
        if (currentStatus.statusDesc !== VALUE_ALL) {
          const allStatus = status[status.length - 1]

          newStatus[index] = { ...currentStatus, checked: !currentStatus.checked }
          newStatus[status.length - 1] = { ...allStatus, checked: false }
        } else {
          newStatus = newStatus.map((item) => ({
            ...item,
            checked: item.eligible ? !currentStatus.checked : false
          }))
        }

        setStatus(newStatus)
        setStatusField(newStatus)
        console.log(newStatus)

        let newStatusCode = getNewStatuCode(newStatus)
        console.log(newStatusCode)

        setStatusCodesField(newStatusCode)
        setStatusTabChanged(true)
        manualRefresh()
      }

  function getNewStatuCode(newStatus) {
    let temp = []
    newStatus
      .filter((v) => v.checked)
      .map((v) => temp.push(v.statusCode))

    return temp
  }

  const showArchivedChange = (e) => {
    console.log('showArchivedChange')
    console.log(e.target.checked)
    setShowArchivedField(e.target.checked)
    setStatusTabChanged(true)
    manualRefresh()
  }

  const showDirectSIChange = (e) => {
    console.log('showDirectSIChange')
    console.log(e.target.checked)
    setShowDirectSIField(e.target.checked)
    setStatusTabChanged(true)
    manualRefresh()
  }

  const onBLCheckBoxChange =
    (index) =>
      ({ target: { checked } }) => {
        console.log('onBLCheckBoxChange ' + index)
        let newBls = [...bls]
        const bl = bls[index]
        if (bl.statusDesc !== VALUE_ALL) {
          const allBl = bls[bls.length - 1]

          newBls[index] = { ...bl, checked: !bl.checked }
          newBls[bls.length - 1] = { ...allBl, checked: false }
        } else {
          newBls = newBls.map((item) => ({
            ...item,
            checked: !bl.checked
          }))
        }

        setBls(newBls)
        setBlsField(newBls)
        console.log(newBls)

        let newBlList = getNewBls(newBls)
        console.log('newBlList', newBlList)
        setBlAppTypeCodesField(newBlList)
        setStatusTabChanged(true)

        manualRefresh()
      }

  function getNewBls(newBls) {
    let temp = []
    newBls.filter((v) => v.checked).map((v) => temp.push(v.statusCode))

    return temp
  }

  const onCarrierCheckBoxChange =
    (index) =>
      ({ target: { checked } }) => {
        console.log('onCarrierCheckBoxChange ' + index)
        let newCarriers = [...carriers]
        const carrier = carriers[index]
        console.log(carrier)
        if (carrier.nameOth !== VALUE_ALL) {
          const allCarrier = carriers[carriers.length - 1]

          newCarriers[index] = { ...carrier, checked: !carrier.checked }
          newCarriers[carriers.length - 1] = { ...allCarrier, checked: false }
        } else {
          newCarriers = newCarriers.map((item) => ({
            ...item,
            checked: !carrier.checked
          }))
        }

        setCarriers(newCarriers)
        setCarriersField(newCarriers)

        let temp = []
        newCarriers
          .filter((v) => v.checked)
          .forEach((v) => temp.push(v.carrierPartyId))

        console.log(temp)

        setPartyIdsField(temp)
        setStatusTabChanged(false)

        manualRefresh()
      }

  let searchCriteria = {
    filterText: !fromBookingPage ? filterTextField.value : searchBookingID,
    dateRange: dateRangeField.value,
    blAppTypeCodes: blAppTypeCodesField.value,
    statusCodes: statusCodesField.value,
    showArchived: showArchivedField.value,
    carrierCodes: partyIdsField.value,
    partyIds: partyIdsField.value,
    sortCode: Utils.isEmptyString(sortCodeField.value)
      ? 1
      : sortCodeField.value,
    showDirectSIOnly: showDirectSIField.value
  }

  const [carriers, setCarriers] = useState([])

  const [status, setStatus] = useState([])
  const [bls, setBls] = useState([])

  const [loadingCriteria, setLoadingCriteria] = useState()
  const [, setPageError] = useState(false)
  const [refresh, setRefresh] = useState(false)

  useEffect(() => {
    setPageError(false)
    setSortCodeField(
      sortCodeField.value == null || sortCodeField.value == ''
        ? 1
        : sortCodeField.value
    )

    if (sortByList == null) {
      //fetch sort code when the page is first loaded, the result will remain static
      getSortingType()
    }

    //get status code and count
    fetchStatusCodeAndCountFromAPI()

  }, [refresh])

  useEffect(() => {
    if (location.state && location.state.fromMyBooking) {
      setFilterText(searchBookingID)
      setFilterTextField(searchBookingID)
      setFromBookingPage(false)
    }
  }, [])

  function populatePartyId(dat) {
    console.log(dat)
    let FromApi = SiApiParser.parsePartyId(dat)
    console.log(FromApi)
    if (FromApi[0] !== undefined) {
      FromApi.map((elem) => (elem.bookingCount = elem.shippingCount))
      let newRecord = { ...FromApi[0] }
      newRecord.nameOth = 'ALL'
      newRecord.bookingCount = FromApi.reduce((a, b) => a + b.shippingCount, 0)
      FromApi = [...FromApi, newRecord]

      console.log(FromApi)

      let partyIdsForSearch = getPartyIdForSIList(dat)
      console.log(partyIdsForSearch)
      console.log(partyIdsField)
      console.log(searchCriteria)

      getShippingInfoList()
    } else {
      setLoading(false)
      setPartyIdsField(
        Utils.isEmptyString(partyIdsField.value) ? [] : partyIdsField.value
      )
    }

    if (reloadCarrierCheckBoxNeeded()) {
      setCarriers(FromApi)
      setCarriersField(FromApi)
    } else {
      setCarriers(carriersField.value)
    }
  }
  function fetchPartyIdFromAPI() {
    SIApi.fetchPartyId(fetchRecords, searchCriteria, populatePartyId, onError)
  }

  function reloadCarrierCheckBoxNeeded() {
    return statusTabChangedField.value
  }

  function getPartyIdForSIList(carrierList) {
    console.log('getPartyIdForBookingList')
    let partyIdss = []
    if (reloadCarrierCheckBoxNeeded()) {
      console.log('loading party id from api')

      carrierList.map((v) => {
        console.log(v.carrierPartyId)
        partyIdss.push(v.carrierPartyId)
      })

      searchCriteria.carrierCodes = partyIdss

      setPartyIdsField(partyIdss)
    } else {
      console.log('loading party id from hisotry')
      setPartyIdsField(partyIdsField.value)
    }
    return partyIdss
  }

  function populateStatusCodeAndCount(dat) {
    let isHelpdesk = false
    const data = SiApiParser.parseStatusCodeAndCount(dat, false, isHelpdesk)
    console.log(data)

    setTotalCount(data.totalCount)
    const SiStatusList = data.siStatusList

    setStatus(
      Utils.isEmptyObject(statusField.value) ? SiStatusList : statusField.value
    )

    fetchBLApplicationTypeFromAPI()
    fetchPartyIdFromAPI()
  }

  function fetchBLApplicationTypeFromAPI() {
    SIApi.fetchBLApplicationType(
      fetchRecords,
      searchCriteria,
      populateBLApplicationType,
      onBLApplicationTypeError
    )
  }

  function onBLApplicationTypeError(error) {
    console.log(error)
  }

  function populateBLApplicationType(dat) {
    console.log(dat)
    let BLList = SiApiParser.parseBlApplicationType(dat)
    console.log(BLList)
    setBls(Utils.isEmptyObject(blsField.value) ? BLList : blsField.value)
  }

  function fetchStatusCodeAndCountFromAPI() {
    SIApi.fetchStatusCodeAndCount(
      fetchRecords,
      populateStatusCodeAndCount,
      onStatusFilterError
    )
  }

  function onStatusFilterError(error) {
    //when filter load got error, dont show the error page, instead , just condition to next call
    fetchBLApplicationTypeFromAPI()
    fetchPartyIdFromAPI()
  }

  function populateSortingType(dat) {
    console.log(dat)
    const list = ApiParser.parseSortingType(dat)
    console.log(list)
    setSortByList(list)
    setSortByListField(list)
  }

  function getLeftCount() {
    return totalCount - shownCount
  }

  function getSortingType() {
    Api.fetchSortingType(fetchRecords, populateSortingType, onError)
  }

  function onError(error) {
    setPageError(true)
    setLoading(false)
  }

  function populateShippingInfoList(data) {
    setSiList(data.content)
    setLoading(false)
    setShownCount(data.totalElements)
  }
  function getShippingInfoList() {
    SIApi.fetchShippingInfoList(
      fetchRecords,
      searchCriteria,
      populateShippingInfoList,
      onError
    )
    setLoadingCriteria(searchCriteria)
  }

  return (
    <>
      {/* Placeholder for summary results and filter panel */}
      <Box
        style={{
          flexDirection: 'column',
          display: 'flex'
        }}
      >
        <Toolbar id='back-to-top-anchor' style={{ minHeight: 0 }} />
        <Grid container xs={12} sm={12} spacing={2} justify='center'>
          {/* Content :: Left pane includes tabs, summary card etc. */}
          <CngGridItem xs={12} sm={9}>
            <Card>
              <CardContent>
                <Grid container xs={12} sm={12}>
                  <CngGridItem xs={12} sm={12}>
                    <ButtonTabComponent.FormBody
                      listCount={shownCount}
                      shownCount={totalCount}
                      sortByList={sortByList}
                      handleSortByChange={handleSortByChange}
                    />
                  </CngGridItem>

                  <CngGridItem xs={12} sm={12}>
                    <Box class='summary-list-items--container mt-25'>
                      <SIListComponent.FormBody
                        formLoading={loading}
                        isViewSi={isViewSi}
                        shownCount={shownCount}
                        searchCriteria={loadingCriteria}
                        siList={siList}
                        showNotification={showNotification}
                        handleClearAllButtonClick={
                          handleClearFiltreAndTextButtonClick
                        }
                        leftCount={getLeftCount()}
                        refreshAction={manualRefresh}
                        setPageError={setPageError}
                      />
                    </Box>
                  </CngGridItem>
                </Grid>
              </CardContent>
            </Card>
          </CngGridItem>

          {/* Content :: Right pane includes free text, filter panel etc. */}
          <CngGridItem xs={12} sm={3}>
            <Grid container xs={12} sm={12} spacing={2}>
              <CngGridItem xs={12} sm={12}>
                <FreeTextSearchComponent.FormBody
                  handleInputChange={handleFilterTextChange}
                  filterText={filterText}
                  handelFreeTextSearch={handelFreeTextSearch}
                />
              </CngGridItem>

              <CngGridItem xs={12} sm={12}>
                <SIFilterPanelComponent
                  handleClearAllButtonClick={handleClearAllButtonClick}
                  onStatusCheckBoxChange={onStatusCheckBoxChange}
                  showArchivedChange={showArchivedChange}
                  onCarrierCheckBoxChange={onCarrierCheckBoxChange}
                  onBLCheckBoxChange={onBLCheckBoxChange}
                  showDirectSIChange={showDirectSIChange}
                  dateRange={dateRangeField.value}
                  dateRangeChange={dateRangeChange}
                  carriers={carriers}
                  bls={bls}
                  status={status}
                  showArchived={showArchived}
                  showDirectSI={showDirectSI}
                  draft={true}
                  showStatus
                />
              </CngGridItem>

              <CngGridItem xs={12} sm={9} shouldHide={loading ? false : true}>
                <CngBackdrop loading={loading} />
              </CngGridItem>
            </Grid>
          </CngGridItem>

          <ScrollTop>
            <Fab color='secondary' size='small' aria-label='scroll back to top'>
              <KeyboardArrowUpIcon />
            </Fab>
          </ScrollTop>
        </Grid>
      </Box>
    </>
  )
}

const ViewOnlySIFormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  FormFields: FormFields
})

export default ViewOnlySIFormProperties
