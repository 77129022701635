import React, { useEffect, useRef, useState } from 'react'
import { constants, useServices } from 'cng-web-lib'
import CngAdminApiUrls from 'src/apiUrls/CngAdminApiUrls.js'
import ShippingInstructionApiUrls from 'src/apiUrls/ShippingInstructionApiUrls.js'

function fetchSortingType(fetchRecords, onSuccess, onError) {
  fetchRecords.execute(
    ShippingInstructionApiUrls.SORTING_TYPE,
    {},
    (data) => {
      console.log('si sort type is back')
      console.log(data)
      onSuccess(data)
    },
    (error) => {
      onError(error)
    }
  )
}

function fetchStatusCodeAndCount(fetchRecords, onSuccess, onError) {
  fetchRecords.execute(
    ShippingInstructionApiUrls.SI_STATUS_LIST,
    {},
    (data) => {
      console.log('si status is back ')
      console.log(data)
      onSuccess(data)
    },
    (error) => {
      onError(error)
    }
  )
}

function fetchBLApplicationType(
  fetchRecords,
  searchCriteria,
  onSuccess,
  onError
) {
  console.log('fetchBLApplicationType')
  console.log(searchCriteria)
  fetchRecords.execute(
    ShippingInstructionApiUrls.BL_APPLICATION_TYPE_LIST,
    {
      customData: searchCriteria
    },
    (data) => {
      console.log('APPLICATION LIST is back')
      console.log(data)
      onSuccess(data)
    },
    (error) => {
      onError(error)
    }
  )
}

function fetchPartyId(fetchRecords, searchCriteria, onSuccess, onError) {
  console.log('fetchPartyIdFromAPI')
  console.log(searchCriteria)
  fetchRecords.execute(
    ShippingInstructionApiUrls.SI_CARRIER_LIST,
    {
      customData: searchCriteria
    },
    (data) => {
      console.log('SI party is back')
      console.log(data)
      onSuccess(data)
    },
    (error) => {
      onError(error)
    }
  )
}

function previewSI(securedSendRequest, shippingInfo) {
  securedSendRequest.execute(
    'POST',
    ShippingInstructionApiUrls.PREVIEW_SI,
    // { id: 2009, carrierPartyId: 10301 },
    shippingInfo,
    (data) => {
      console.log('success')
      console.log(data)

      var blob = data.data

      console.log(blob)

      var link = document.createElement('a')

      link.href = window.URL.createObjectURL(blob)
      link.download = 'ShippingInfo_' + shippingInfo.shippingInfoId + '.pdf'

      document.body.appendChild(link)

      console.log(link)
      // Start download
      link.click()

      // Clean up and remove the link
      link.parentNode.removeChild(link)
    },
    (error) => {
      console.log(error)
    },
    onComplete,

    { responseType: 'blob' }
  )
}

function onComplete() {
  console.log('complete')
}

function fetchShippingInfoList(
  fetchRecords,
  searchCriteria,
  onSuccess,
  onError
) {
  console.log('getBookingList')
  console.log(searchCriteria)
  fetchRecords.execute(
    ShippingInstructionApiUrls.SHIPPING_LIST,
    {
      customData: searchCriteria
    },
    (data) => {
      console.log('si is back')
      console.log('data: ' + data)
      onSuccess(data)
    },
    (error) => {
      onError(error)
    }
  )
}

function fetchSIDetailsById(fetchRecords, siRequest, onSuccess, helpdesk) {
  console.log('getBookingDetailsById...')
  fetchRecords.execute(
    helpdesk? ShippingInstructionApiUrls.HELPDESK_SI_DETAILS : ShippingInstructionApiUrls.SI_DETAILS,
    { customData: siRequest },
    (data) => {
      onSuccess(data)
    },
    (error) => {
      console.log(error)
    }
  )
}

function fetchAuditTrailListing(fetchRecords, keyReferenceNo, onSuccess) {
  console.log('fetchAuditTrailListing')
  console.log(keyReferenceNo)
  fetchRecords.execute(
    CngAdminApiUrls.AUDIT_TRAIL_LISTING,
    {
      filters: [
        {
          field: 'auditModuleCode',
          operator: 'EQUAL',
          value: 'CLOGS_SI'
        },
        {
          field: 'keyReferenceNo',
          operator: 'EQUAL',
          value: keyReferenceNo
        }
      ]
    },
    (data) => {
      console.log('fetchAuditTrailListing back')
      console.log(data)
      onSuccess(data.content)
    },
    (error) => {
      console.log(error)
    }
  )
}

function uploadDocument(createRecord, formData, loadDocumentInfoToSI, onError) {
  createRecord.execute(
    ShippingInstructionApiUrls.DOCUMENT_UPLOAD,
    formData,
    loadDocumentInfoToSI,
    onError
  )
}

function uploadSIDocument(createRecord, formData, onSuccess, onError) {
  createRecord.execute(
    ShippingInstructionApiUrls.UPLOAD_SI_UPLOAD,
    formData,
    onSuccess,
    onError
  )
}

function fetchUploadSIDocuments(fetchRecords, siRequest, onSuccess) {
  fetchRecords.execute(
    ShippingInstructionApiUrls.UPLOAD_SI_QUERY,
    { customData: siRequest },
    onSuccess
  )
}

function fetchHelpdeskStatusCodeAndCount(fetchRecords, onSuccess, onError) {
  fetchRecords.execute(
    ShippingInstructionApiUrls.HELPDESK_STATUS_LIST,
    {},
    (data) => {
      console.log('si status is back ')
      console.log(data)
      onSuccess(data)
    },
    (error) => {
      onError(error)
    }
  )
}

function fetchHelpdeskShippingInfoList(
  fetchRecords,
  searchCriteria,
  onSuccess,
  onError
) {
  console.log('getHelpdeskBookingList')
  console.log(searchCriteria)
  fetchRecords.execute(
    ShippingInstructionApiUrls.HELPDESK_SHIPPING_LIST,
    {
      customData: searchCriteria
    },
    (data) => {
      console.log('si is back')
      console.log('data: ' + data)
      onSuccess(data)
    },
    (error) => {
      onError(error)
    }
  )
}

function fetchHelpdeskPartyId(fetchRecords, searchCriteria, onSuccess, onError) {
  
  fetchRecords.execute(
    ShippingInstructionApiUrls.HELPDESK_SI_CARRIER_LIST,
    {
      customData: searchCriteria
    },
    (data) => {
      onSuccess(data)
    },
    (error) => {
      onError(error)
    }
  )
}

function fetchHelpdeskBLApplicationType(
  fetchRecords,
  searchCriteria,
  onSuccess,
  onError
) {
  fetchRecords.execute(
    ShippingInstructionApiUrls.HELPDESK_BL_APPLICATION_TYPE_LIST,
    {
      customData: searchCriteria
    },
    (data) => {
      onSuccess(data)
    },
    (error) => {
      onError(error)
    }
  )
}
const ShippingInstructionApi = Object.freeze({
  fetchSortingType,
  fetchStatusCodeAndCount,
  fetchBLApplicationType,
  fetchPartyId,
  previewSI,
  fetchShippingInfoList,
  fetchSIDetailsById,
  fetchAuditTrailListing,
  uploadDocument,
  uploadSIDocument,
  fetchUploadSIDocuments,
  fetchHelpdeskStatusCodeAndCount,
  fetchHelpdeskShippingInfoList,
  fetchHelpdeskPartyId,
  fetchHelpdeskBLApplicationType
})

export default ShippingInstructionApi
