import React, { useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'

import MySIFormProperties from './MySIFormProperties'
import { components } from 'cng-web-lib'
import pathMap from 'src/paths/PathMap_ShippingInstruction'

const {
  form: { CngViewForm }
} = components

function MySIPage({ showNotification }) {
  const { id } = useParams()
  const history = useHistory()


  function onSubmit(data) {
    console.log('submit', data)


    history.push('/');
    history.replace({ pathname: pathMap.MY_SI, state: data })
  }

  return (

    // use CngViewForm here for customizable button set
    <CngViewForm
      fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
      history={history}
      showNotification={showNotification}
      bodySection={
        <MySIFormProperties.FormFields
          disabled={false}

          showNotification={showNotification}
        />
      }
      formikProps={{
        ...MySIFormProperties.formikProps,
        onSubmit: onSubmit
      }}
    // toClientDataFormat={PollingFormProperties.toClientDataFormat}
    // toServerDataFormat={PollingFormProperties.toServerDataFormat}
    />

  )
}

export default MySIPage
