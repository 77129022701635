import { Yup } from 'cng-web-lib'
import * as ValidationRegex from 'src/views/shippinginstruction/shared/validation/ValidationRegex'
import ValidationMessageTranslationText from 'src/views/shippinginstruction/shared/validation/ValidationMessageTranslationText'
import FRBValidationMessageTranslationText from 'src/views/freightbooking/shared/validation/ValidationMessageTranslationText'

function ScheduleValidationSchema() {
  const validationMessageTranslation = ValidationMessageTranslationText()
  const frbValidationMessageTranslation = FRBValidationMessageTranslationText()

  return Yup.object({
    carrierPartyId: Yup.string()
      .required(validationMessageTranslation.requiredMessage)
      .nullable(),

    polCode: Yup.string()
      .required(validationMessageTranslation.requiredMessage)
      .test(
        'polCode',
        frbValidationMessageTranslation.PolPodSameMsg,
        function (val) {
          const { podCode } = this.parent
          if (podCode && podCode === val) return val == null
          return true
        }
      )
      .nullable(),

    podCode: Yup.string()
      .required(validationMessageTranslation.requiredMessage)
      .when('polCode', {
        is: (value) => value && value.length > 0,
        then: Yup.string()
          .nullable()
          .notOneOf(
            [Yup.ref('polCode')],
            frbValidationMessageTranslation.PolPodSameMsg
          )
      })
      .nullable(),

    precarriagevoyage: Yup.string()
      .matches(
        ValidationRegex.excludeSpecialCharRegx,
        validationMessageTranslation.invalidStrMsg
      )
      .max(17, validationMessageTranslation.wordLimitExceedMsg)
      .nullable(),
    precarriagevessel: Yup.string()
      .matches(
        ValidationRegex.excludeSpecialCharRegx,
        validationMessageTranslation.invalidStrMsg
      )
      .max(35, validationMessageTranslation.wordLimitExceedMsg)
      .nullable(),
    portOfLoadingTerminal: Yup.string()
      .max(255, validationMessageTranslation.wordLimitExceedMsg)
      .nullable(),
    portOfDischargeTerminal: Yup.string()
      .max(255, validationMessageTranslation.wordLimitExceedMsg)
      .nullable(),
    vesselName: Yup.string()
      .required(validationMessageTranslation.requiredMessage)
      .nullable(),
    voyageNo: Yup.string()
      .required(validationMessageTranslation.requiredMessage)
      .nullable()
  })
}

export default ScheduleValidationSchema
