import { Yup } from 'cng-web-lib'
import * as ValidationRegex from 'src/views/shippinginstruction/shared/validation/ValidationRegex'
import ValidationMessageTranslationText from 'src/views/shippinginstruction/shared/validation/ValidationMessageTranslationText'
import moment from 'moment'
import FRBValidationMessageTranslationText from 'src/views/freightbooking/shared/validation/ValidationMessageTranslationText'

function ShipmentValidationSchema() {
  const validationMessageTranslation = ValidationMessageTranslationText()
  const frbValidationMessageTranslation = FRBValidationMessageTranslationText()

  return Yup.object({
    // Pending docs

    moveType: Yup.string()
      .required(validationMessageTranslation.requiredMessage)
      .nullable(),
    placeOfReceiptCode: Yup.string()
      // .required(validationMessageTranslation.requiredMessage)
      .nullable()
      .test(
        'placeOfDeliveryCode',
        frbValidationMessageTranslation.PorPoDSameMsg,
        function (val) {
          const { placeOfDeliveryCode } = this.parent
          if (placeOfDeliveryCode && placeOfDeliveryCode === val)
            return val == null
          return true
        }
      )
      .when('placeOfReceiptRequired', {
        is: true,
        then: Yup.string()
          .required(validationMessageTranslation.requiredMessage)
          .nullable()
      }),

    placeOfDeliveryCode: Yup.string()
      //.required(validationMessageTranslation.requiredMessage)
      .nullable()
      .when('placeOfReceiptCode', {
        is: (value) => value && value.length > 0,
        then: Yup.string()
          .nullable()
          .notOneOf(
            [Yup.ref('placeOfReceiptCode')],
            frbValidationMessageTranslation.PorPoDSameMsg
          )
      })
      .when('placeOfDeliveryRequired', {
        is: true,
        then: Yup.string()
          .required(validationMessageTranslation.requiredMessage)
          .nullable()
      }),

    cargoReadyDate: Yup.date()
      .required(validationMessageTranslation.requiredMessage)
      .typeError(frbValidationMessageTranslation.FrbDateTypeErrorMsg)
      // .min(
      //   moment().format('DD-MMM-YYYY'),
      //   frbValidationMessageTranslation.CargoReadyDateBeforeTodayMsg
      // )
      .nullable(),

    cargoDeliveryDate: Yup.date()
      .required(validationMessageTranslation.requiredMessage)
      .typeError(frbValidationMessageTranslation.FrbDateTypeErrorMsg)
      .min(
        Yup.ref('cargoReadyDate'),
        frbValidationMessageTranslation.CargoDeliveryDateBeforeCargoReadyDateMsg
      )
      .nullable(),

    placeOfReceipt: Yup.string()
      .required(validationMessageTranslation.requiredMessage)
      .matches(
        ValidationRegex.excludeSpecialCharRegx,
        validationMessageTranslation.invalidStrMsg
      )
      .required(validationMessageTranslation.requiredMessage)
      .max(512, validationMessageTranslation.wordLimitExceedMsg)
      .nullable(),
    placeOfDelivery: Yup.string()
      .required(validationMessageTranslation.requiredMessage)
      .matches(
        ValidationRegex.excludeSpecialCharRegx,
        validationMessageTranslation.invalidStrMsg
      )
      .required(validationMessageTranslation.requiredMessage)
      .max(512, validationMessageTranslation.wordLimitExceedMsg)
      .nullable(),
    por_status: Yup.string().max(10).nullable(),
    pod_status: Yup.string().max(10).nullable()
  })
}

export default ShipmentValidationSchema
