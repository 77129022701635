export const phoneRegx = /^(\(?\+?[0-9]*\)?)?[0-9_\- \(\)]*$/
export const excludeSpecialCharRegx = /^[^~$^+=|<>]*$/
export const excludeSpecialCharRegx2 = /^[^<+>^`~$^=|]*$/
export const addressMaxRegx = /^(.*\n?){0,3}(.*)$/
export const spaceNotAllowRegx = /^((?=\S).*\S\n)*(?=\S).*\S/
export const HSCodeRegx = /^[A-Za-z0-9]*$/
export const ContWeightVolumeRegx =
  /(^[1-9][0-9]{1,5}(?:\.[0-9]{1,3})?$)|(^0(?:\.[0-9]{1,3})?$)/
export const weightVolumeRegx = /^[0-9]{1,7}(?:\.[0-9]{1,3})?$/
export const dgWeightVolumeRegx = /^[0-9]{1,6}(?:\.[0-9]{1,3})?$/
export const lengthWidthHeightRegx =
  /(^[1-9][0-9]{1,6}(?:\.[0-9]{1,3})?$)|(^0(?:\.([0-9]{1,2})?[1-9])?$)/
export const partyNameRegx = /^.*\S.*$/
export const emojiRegx =
  /^((?!(.*(\ud83c[\udf00-\udfff]|\ud83d[\udc00-\ude4f]|\ud83d[\ude80-\udeff]).*)).)*$/
export const emojiEndNewLineRegx =
  /^((?!(.*(\ud83c[\udf00-\udfff]|\ud83d[\udc00-\ude4f]|\ud83d[\ude80-\udeff]).*)).\n*)*[^\n]$/
export const addressMaxLinesRegx = /^(.*\n?){0,3}(.*)$/
export const emailListRegx =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}) *)(, *(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}) *))*,? *$/
export const emailListLengthRegx =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}) *)(, *(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}) *)){0,5},? *$/
export const partyNameLengthRegx = /^.{0,41}$/
export const partyNamePILLengthRegx = /^.{0,46}$/
export const partyAddressLengthRegx = /^(?!(.|\n)*.{36,}(.|\n)*)(.|\n)*$/
export const partyAddressPILLengthRegx = /^(?!(.|\n)*.{47,}(.|\n)*)(.|\n)*$/
//export const partyAddressMaxLineRegx = /^(.*\n?){0,3}(.*)$/
export const agentAddressMaxLineRegx = /^(.*\n?){0,5}(.*)$/
export const agentAddressLengthRegx = /^(?!(.|\n)*.{217,}(.|\n)*)(.|\n)*$/
export const undgRegex = /^([0-9]{4})?$/
export const blNumberRegex = /^[a-zA-Z0-9_-]*$/
