import ShippingInstructionValidationKeys from 'src/constants/locale/key/ShippingInstructionValidation'
import Namespace from 'src/constants/locale/Namespace'
import { useTranslation, constants } from 'cng-web-lib'

const {
  locale: {
    key: { CommonValidationMessageKeys }
  }
} = constants

const ValidationMessageTranslationText = () => {
  const { translate } = useTranslation([
    Namespace.SHIPPING_INSTRUCTION_VALIDATION,
    Namespace.COMMON_VALIDATION_MSG
  ])

  let requiredMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.REQUIRED
  )

  let dateTypeErrorMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.DATE_TYPE_ERROR
  )
  let invalidEmailMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.INVALID_EMAIL
  )

  let valueTooSmallMsg = translate(
    Namespace.SHIPPING_INSTRUCTION_VALIDATION,
    ShippingInstructionValidationKeys.VALUE_TO_SMALL
  )

  let valueTooBigMsg = translate(
    Namespace.SHIPPING_INSTRUCTION_VALIDATION,
    ShippingInstructionValidationKeys.VALUE_TOO_BIG
  )

  let numberErrorMsg = translate(
    Namespace.SHIPPING_INSTRUCTION_VALIDATION,
    ShippingInstructionValidationKeys.NUMBER_ERROR
  )

  let contNumberErrorMsg = translate(
    Namespace.SHIPPING_INSTRUCTION_VALIDATION,
    ShippingInstructionValidationKeys.CONT_NUMBER_ERROR
  )

  let contNumberUniqueMsg = translate(
    Namespace.SHIPPING_INSTRUCTION_VALIDATION,
    ShippingInstructionValidationKeys.CONT_NUMBER_UNIQUE
  )

  let invalidStrMsg = translate(
    Namespace.SHIPPING_INSTRUCTION_VALIDATION,
    ShippingInstructionValidationKeys.INVALID_STRING
  )

  let invalidAddressMsg = translate(
    Namespace.SHIPPING_INSTRUCTION_VALIDATION,
    ShippingInstructionValidationKeys.INVALID_ADDRESS
  )

  let invalidBlankSpaceMsg = translate(
    Namespace.SHIPPING_INSTRUCTION_VALIDATION,
    ShippingInstructionValidationKeys.INVALID_BLANK_SPACE
  )

  let invalidHSCode = translate(
    Namespace.SHIPPING_INSTRUCTION_VALIDATION,
    ShippingInstructionValidationKeys.INVALID_HSCODE
  )

  let IntegerOnlyMsg = translate(
    Namespace.SHIPPING_INSTRUCTION_VALIDATION,
    ShippingInstructionValidationKeys.INTEGER_ONLY
  )

  let invalidPhoneMsg = translate(
    Namespace.SHIPPING_INSTRUCTION_VALIDATION,
    ShippingInstructionValidationKeys.INVALID_PHONE
  )

  let wordLimitExceedMsg = translate(
    Namespace.SHIPPING_INSTRUCTION_VALIDATION,
    ShippingInstructionValidationKeys.WORD_LIMIT_EXCEED
  )

  let invalidPartyName = translate(
    Namespace.SHIPPING_INSTRUCTION_VALIDATION,
    ShippingInstructionValidationKeys.INVALID_PARTY_NAME
  )

  let invalidEmojiMsg = translate(
    Namespace.SHIPPING_INSTRUCTION_VALIDATION,
    ShippingInstructionValidationKeys.INVALID_EMOJI
  )

  let invalidEmojiEndNewLineMsg = translate(
    Namespace.SHIPPING_INSTRUCTION_VALIDATION,
    ShippingInstructionValidationKeys.INVALID_EMOJI_NEW_LINE
  )

  let invalidEmailListMsg = translate(
    Namespace.SHIPPING_INSTRUCTION_VALIDATION,
    ShippingInstructionValidationKeys.INVALID_EMAIL_LIST
  )

  let exceedEmailListLengthMsg = translate(
    Namespace.SHIPPING_INSTRUCTION_VALIDATION,
    ShippingInstructionValidationKeys.EXCEED_EMAIL_LIST_LENGTH
  )

  let invalidPartyNameLengthMsg = translate(
    Namespace.SHIPPING_INSTRUCTION_VALIDATION,
    ShippingInstructionValidationKeys.INVALID_PARTY_NAME_LENGTH_MSG
  )
  let invalidPartyNamePILLengthMsg = translate(
    Namespace.SHIPPING_INSTRUCTION_VALIDATION,
    ShippingInstructionValidationKeys.INVALID_PARTY_NAME_PIL_LENGTH_MSG
  )
  let invalidPartyAddressLengthMsg = translate(
    Namespace.SHIPPING_INSTRUCTION_VALIDATION,
    ShippingInstructionValidationKeys.INVALID_PARTY_ADDRESS_LENGTH_MSG
  )
  let invalidPartyAddressPILLengthMsg = translate(
    Namespace.SHIPPING_INSTRUCTION_VALIDATION,
    ShippingInstructionValidationKeys.INVALID_PARTY_ADDRESS_PIL_LENGTH_MSG
  )

  let agentAddressExceedMaxLines = translate(
    Namespace.SHIPPING_INSTRUCTION_VALIDATION,
    ShippingInstructionValidationKeys.AGENT_ADDRESS_EXCEED_MAX_LINES
  )

  let agentAddressExceedMaxLength = translate(
    Namespace.SHIPPING_INSTRUCTION_VALIDATION,
    ShippingInstructionValidationKeys.AGENT_ADDRESS_EXCEED_MAX_LENGTH
  )

  let invalidBLNumber = translate(
    Namespace.SHIPPING_INSTRUCTION_VALIDATION,
    ShippingInstructionValidationKeys.INVALID_BL_NUMBER
  )
  return {
    requiredMessage,
    dateTypeErrorMessage,
    invalidEmailMessage,
    valueTooSmallMsg,
    valueTooBigMsg,
    numberErrorMsg,
    contNumberErrorMsg,
    contNumberUniqueMsg,
    invalidStrMsg,
    invalidAddressMsg,
    invalidBlankSpaceMsg,
    invalidHSCode,
    IntegerOnlyMsg,
    invalidPhoneMsg,
    wordLimitExceedMsg,
    invalidPartyName,
    invalidEmojiMsg,
    invalidEmojiEndNewLineMsg,
    invalidEmailListMsg,
    exceedEmailListLengthMsg,
    invalidPartyNameLengthMsg,
    invalidPartyNamePILLengthMsg,
    invalidPartyAddressLengthMsg,
    invalidPartyAddressPILLengthMsg,
    agentAddressExceedMaxLines,
    agentAddressExceedMaxLength,
    invalidBLNumber
  }
}

export default ValidationMessageTranslationText
